import { zodResolver } from '@hookform/resolvers/zod';
import { ZodIssueCode, z } from 'zod';

import { useTranslations } from '@/hooks';
import { Lang } from '@/i18n/types';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{10,}$/;

const zodAccountRecovery = (lang: Lang) => {
  const {
    components: {
      forms: {
        passwordMustMatch,
        passwordRequirementsDoesNotMatch,
        passwordAtLeast10Chars,
        required,
      },
    },
  } = useTranslations(lang);

  const ResetPassword = z
    .object({
      password: z
        .string({ required_error: required })
        .min(10, { message: passwordAtLeast10Chars })
        .refine(
          (value) => passwordRegex.test(value),
          passwordRequirementsDoesNotMatch,
        ),
      confirmPassword: z
        .string({ required_error: required })
        .min(10, { message: passwordAtLeast10Chars })
        .refine(
          (value) => passwordRegex.test(value),
          passwordRequirementsDoesNotMatch,
        ),
    })
    .superRefine(({ password, confirmPassword }, ctx) => {
      if (password !== confirmPassword) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: passwordMustMatch,
          fatal: true,
          path: ['confirmPassword'],
        });
      }
    });

  const resetPasswordResolver = zodResolver(ResetPassword);

  return { resetPasswordResolver };
};

export default zodAccountRecovery;
