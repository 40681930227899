import React, { FC, useEffect, useState } from 'react';

import styles from './index.css';
import type { ErrorMessagesProps } from './types';

const ErrorMessage: FC<ErrorMessagesProps> = ({
  errorMessages,
  dataTestId,
  id,
}) => {
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const action = window.location.href.split('?')[1];
      if (action === 'action=connected-provider') {
        setIsSuccessMessage(true);
      }
    }
  }, [typeof window !== 'undefined']);

  return (
    <ul className={styles.errorsList} data-testid={dataTestId} id={id}>
      {errorMessages.map((message) => (
        <li
          key={message}
          className={`${styles.errorMessage} ${isSuccessMessage ? styles.successMessage : ''}`}
        >
          {message}
        </li>
      ))}
    </ul>
  );
};

export default ErrorMessage;
