import { MapStore, map } from 'nanostores';

import { AUTH_ENDPOINT } from '@/constants';
import { fetch } from '@/services';
import { getErrors, setLocalStorage } from '@/utils';

import { get2FAInfo, getIpAddress } from '@/utils/browser';
import type {
  GetUserToken,
  UserCredentials,
  UserPasswordResetCredentials,
  UserPasswordResetVerify,
  UserSetNewPasswordCredentials,
  UserSetter,
  UserState,
} from './types';

class User {
  $user: MapStore<UserState> = map<UserState>({
    loading: false,
    fetched: false,
    errors: [],
    required2FA: false,
  });

  private set({ data, errors, fetched, loading, required2FA }: UserSetter) {
    const user = this.$user.get();

    const localData = data ?? user.data;
    const localErrors = errors ?? user.errors;
    const localFetched = fetched ?? user.fetched;
    const localLoading = loading ?? user.loading;
    const localRequired2FA = required2FA ?? user.required2FA;

    this.$user.set({
      data: localData,
      errors: localErrors,
      fetched: localFetched,
      loading: localLoading,
      required2FA: localRequired2FA,
    });
  }

  private async getUserToken(userCredentials: UserCredentials): GetUserToken {
    try {
      const clientInfo = await get2FAInfo();

      const response = await fetch.post(AUTH_ENDPOINT + '/login', {
        ...userCredentials,
        ...clientInfo,
      });

      if (response.status === 428) {
        return { required2FA: true };
      }

      if (response.status !== 200) {
        this.set({
          errors: [response.data.detail],
          fetched: false,
          loading: false,
          required2FA: false,
        });
        return;
      }

      return response.data;
    } catch (error: any) {
      const { response } = error;
      return { detail: response.data };
    }
  }

  private async getResetPasswordToken(params: UserPasswordResetCredentials) {
    try {
      const response = await fetch.post(
        AUTH_ENDPOINT + '/token/password_reset',
        params,
      );

      if (response.status !== 200) {
        return { detail: response.data.detail };
      }

      return response.data;
    } catch (error: any) {
      return { detail: error };
    }
  }

  private async setNewPassword(params: UserSetNewPasswordCredentials) {
    try {
      const response = await fetch.post(
        AUTH_ENDPOINT + '/password/set',
        params,
      );

      if (response.status !== 200) {
        return false;
      }

      return true;
    } catch (error: any) {
      const { response } = error;
      return { errors: response.data };
    }
  }

  private async getVerificationPasswordResetToken(
    params: UserPasswordResetVerify,
  ) {
    try {
      const response = await fetch.post(AUTH_ENDPOINT + '/token/verify', {
        token: params.token,
        type: params.type,
      });

      if (!response || response.status !== 200) {
        return null;
      }

      return response.data;
    } catch (error: any) {
      const { response } = error;

      return { errors: response.data };
    }
  }

  private async verify2FA(token: string) {
    try {
      const ip = await getIpAddress();

      const response = await fetch.post(AUTH_ENDPOINT + '/2fa/set', {
        token,
        user_ip: ip.ip,
      });
      if (response.data.error || response.status !== 200) {
        return { token: null };
      }

      return { token: response.data.token };
    } catch (error: any) {
      const { response } = error;
      return { errors: response.data };
    }
  }

  async loginInUser(userCredentials: UserCredentials) {
    this.set({ loading: true, errors: [], fetched: false, required2FA: false });
    const token = await this.getUserToken(userCredentials);

    if (token?.required2FA) {
      this.set({
        errors: [],
        fetched: true,
        loading: false,
        required2FA: true,
      });
      return;
    }

    if (token?.detail) {
      return null;
    }

    const accessToken = token?.access_token as string;

    setLocalStorage('accessToken', accessToken);
    setLocalStorage('refreshToken', accessToken);

    return {
      access_token: token?.access_token || null,
      marketplace_token: token?.marketplace_token,
    };
  }

  async resetPassword(userCredentials: UserPasswordResetCredentials) {
    const token = await this.getResetPasswordToken(userCredentials);

    if (token?.detail) {
      const errors = getErrors(token?.detail);
      this.set({ loading: false, errors, fetched: true, required2FA: false });
      return null;
    }

    this.set({
      data: token.token,
      errors: [],
      fetched: true,
      loading: false,
      required2FA: false,
    });

    return token.token;
  }

  async verifyPasswordResetToken(params: UserPasswordResetVerify) {
    const verified = await this.getVerificationPasswordResetToken(params);

    if (!verified) {
      return false;
    }

    this.set({
      data: verified ?? verified?.data,
      errors: [],
      fetched: true,
      loading: false,
      required2FA: false,
    });

    return true;
  }

  async resetPasswordRequest(userCredentials: UserSetNewPasswordCredentials) {
    const passwordChanged = await this.setNewPassword(userCredentials);
    return passwordChanged;
  }

  async verify2FACode(token: string) {
    const verified = await this.verify2FA(token);
    return verified;
  }

  async resetLogin() {
    this.set({
      loading: false,
      errors: [],
      fetched: false,
      required2FA: false,
    });
  }
}

export default new User();
