import React, { FC } from 'react';

import styles from './index.css';
import type { MainHeadingProps } from './types';

const MainHeadings: FC<MainHeadingProps> = ({
  title,
  subtitle,
  dataTestId,
}) => {
  return (
    <div className={styles.group}>
      <h1 className={styles.mainHeading} data-testid={dataTestId}>
        {title}
      </h1>
      {typeof subtitle === 'string' ? (
        <p className={styles.subtitle}>{subtitle}</p>
      ) : (
        subtitle
      )}
    </div>
  );
};

export default MainHeadings;
