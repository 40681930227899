import { D2_BASE_PATH, DEBITOS_MARKETPLACE, IS_DEVELOPMENT } from '@/constants';
import { getCookie, getPreferredLanguage, setCookie } from '@/utils';
import React, { useEffect } from 'react';

const Redirect = () => {
  const getToken = async (
    isManagePage: boolean,
    token: string,
    marketplace_token: string,
  ) => {
    if (isManagePage) {
      if (!token || token === undefined) {
        return { redirectTo: '/login' };
      }
    } else if (
      !isManagePage &&
      token &&
      token !== undefined &&
      token.length > 0
    ) {
      if (!marketplace_token) {
        return { redirectTo: DEBITOS_MARKETPLACE + '/Signout' };
      }
      return { redirectTo: DEBITOS_MARKETPLACE };
    }
  };

  useEffect(() => {
    const checkRedirect = async () => {
      const pathnameArray = window.location.pathname.split('/');
      const isManagePage =
        pathnameArray[pathnameArray.length - 1].includes('manage');
      const token = getCookie('access_token');
      const isLostPassword =
        pathnameArray[pathnameArray.length - 1].includes('lost-password');

      if (isLostPassword) {
        return;
      }

      let marketplace_token = getCookie('debitos_token');
      const tokenRedirect = await getToken(
        isManagePage,
        token,
        marketplace_token,
      );

      if (tokenRedirect?.redirectTo) {
        if (tokenRedirect.redirectTo.includes(DEBITOS_MARKETPLACE)) {
          return (window.location.href = DEBITOS_MARKETPLACE);
        }

        const prefferedLang = getPreferredLanguage();
        const newUrl = `${D2_BASE_PATH}/${prefferedLang}/login`;
        return (window.location.href = newUrl);
      }
    };

    const redirectOnLang = () => {
      const langCookie = getCookie('lang');
      const localLangArray = window.location.pathname.split('/');
      const search = window.location.search;

      if (langCookie) {
        let isSameLang = false;
        localLangArray.forEach((l) => {
          if (langCookie === l) {
            isSameLang = true;
          }
        });

        if (!isSameLang) {
          if (IS_DEVELOPMENT) {
            const [_, __, ...rest] = localLangArray;
            const newPath = ['', langCookie, ...rest].join('/');
            window.location.href = newPath + search;
          } else {
            const [_, __, ___, ...rest] = localLangArray;
            const newPath = ['', 'd2', langCookie, ...rest].join('/');
            window.location.href = newPath + search;
          }
        }
      }
    };

    const setDefaultLangCookie = () => {
      const langCookie = getCookie('lang');

      if (langCookie.length === 0) {
        const localLangArray = window.location.pathname.split('/');
        let newLang = '';

        if (IS_DEVELOPMENT) {
          const [_, oldLang, ...rest] = localLangArray;
          newLang = oldLang;
        } else {
          const [_, __, oldLang, ...rest] = localLangArray;
          newLang = oldLang;
        }
        setCookie('lang', newLang, 30);
      }
    };

    setDefaultLangCookie();
    checkRedirect().then();
    redirectOnLang();
  }, []);
  return <></>;
};

export default Redirect;
