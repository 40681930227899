import React, { FC } from 'react';

import {
  BottomButtons,
  Form,
  Input,
  NeedHelp,
  Separator,
} from '@/components/forms';
import { useLogin, useTranslations } from '@/hooks';
import styles from '../index.css';
import zodLogin from '../zod';
import { DoYouHaveAnDebitosAccountProps } from './types';

const DoYouHaveAnDebitosAccount: FC<DoYouHaveAnDebitosAccountProps> = ({
  lang,
}) => {
  const {
    components: {
      forms: { debitosIdOrEmail, emailAddress, previous },
    },
    pages: {
      sso: { completeStatement },
    },
  } = useTranslations(lang);
  const { doYouHaveAnDebitosAccountEmailResolver } = zodLogin(lang);

  const {
    handleInput,
    submitDoYouHaveAnDebitosAccountEmail,
    fields,
    isLoading,
    loginStore,
  } = useLogin({
    lang,
  });

  const onSubmitFromDoYouHaveAnDebitosAccountToLogin = () => {
    loginStore.setDoYouHaveAnDebitosAccount(false);
  };

  return (
    <>
      <Separator>
        <p className={styles.completeStatement}>{completeStatement}</p>
      </Separator>
      <Form
        onSubmit={submitDoYouHaveAnDebitosAccountEmail}
        resolver={doYouHaveAnDebitosAccountEmailResolver}
      >
        <Input
          name="email"
          id="email"
          type="email"
          required
          label={debitosIdOrEmail}
          // onChange={(e: any) => handleInput(e, 'email')}
          data-testid="login-form-email-input"
          placeholder={emailAddress}
          // value={fields.email?.value}
          // invalid={
          //   typeof fields.email?.invalidText === 'undefined' ? false : true
          // }
          // invalidText={fields.email?.invalidText}
          isDisabled={isLoading}
          hasSeparator={false}
          margin={1.3}
        />
        <NeedHelp lang={lang} isDisabled={isLoading} />
        <BottomButtons
          leftButtonLabel={previous}
          leftButtonIcon="back"
          leftButtonOnClick={onSubmitFromDoYouHaveAnDebitosAccountToLogin}
        />
      </Form>
    </>
  );
};

export default DoYouHaveAnDebitosAccount;
