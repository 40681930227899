import { ArrowLeft, ArrowRight } from '@carbon/icons-react';
import { Button } from '@carbon/react';
import React, { FC } from 'react';

import { LoadSpinner } from '@/components/forms';

import styles from './index.css';
import type { ButtonProps } from './types';

const ButtonComponent: FC<ButtonProps> = ({
  type = 'button',
  label,
  href,
  icon,
  kind = 'primary',
  onClick,
  style,
  isLoading,
  disabled,
  tabOrder,
  dataTestId,
}) => {
  const getClassName = () => {
    switch (kind) {
      case 'primary': {
        return styles.primary;
      }
      case 'secondary': {
        return styles.secondary;
      }
      default: {
        return styles.primary;
      }
    }
  };

  const renderIcon = () => {
    const size = 24;
    switch (icon) {
      case 'leftArrow': {
        return <ArrowLeft size={size} />;
      }
      case 'rightArrow': {
        return <ArrowRight size={size} />;
      }
    }
  };

  const getFocus = () => {
    return tabOrder === -1 ? -1 : undefined;
  };

  return (
    <Button
      type={type}
      size="2xl"
      renderIcon={!isLoading ? renderIcon : undefined}
      style={{
        ...style,
        ...(tabOrder ? { order: tabOrder } : {}),
      }}
      kind={kind}
      href={href}
      onClick={onClick}
      className={`${getClassName()} ${styles.button}`}
      disabled={disabled}
      tabIndex={getFocus()}
      data-testid={dataTestId}
    >
      {isLoading ? <LoadSpinner props={{ size: 24 }} /> : label}
    </Button>
  );
};

export default ButtonComponent;
