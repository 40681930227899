import React, { FC } from 'react';

import { Separator } from '@/components/forms';
import useTranslations from '@/hooks/useTranslations';
import { getExternalLinks } from '@/utils';

import { signupLink, signupStatement } from './index.css';
import { NeedHelpProps } from './types';

const NeedHelp: FC<NeedHelpProps> = ({ lang, isDisabled = false }) => {
  const {
    components: {
      forms: { needHelp, contact },
    },
  } = useTranslations(lang);

  return (
    <Separator>
      <p className={signupStatement}>
        {needHelp}{' '}
        <a
          {...(isDisabled ? {} : { href: getExternalLinks('/contact', lang) })}
          target="_blank"
          className={signupLink}
        >
          {contact}
        </a>
      </p>
    </Separator>
  );
};

export default NeedHelp;
