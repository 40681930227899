import React, { useEffect } from 'react';

import { app } from '@/store';

const Device = () => {
  useEffect(() => {
    app.setDevice();
  }, []);

  return <></>;
};

export default Device;
