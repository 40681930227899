import React, { FC, useEffect } from 'react';

import {
  BottomButtons,
  Checkbox,
  DontHaveAnAccount,
  ErrorMessages,
  Form,
  Input,
  InputSideButton,
  MainHeadings,
  MultiInputForm,
  NeedHelp,
} from '@/components/forms';
import useTranslations from '@/hooks/useTranslations';
import { user as userStore } from '@/store';

import { useLogin } from '@/hooks';
import useUser from '@/hooks/useUser';
import {
  checkRedirectQuery,
  encodeURI,
  getAndReplaceUrlQuery,
  getBasePathname,
  getCookie,
  invalidateCookie,
  setCookie,
  setLocalStorage,
} from '@/utils';

import { SSOSmall } from '@/components';
import { DEBITOS_MARKETPLACE } from '@/constants';
import ConfirmEmail from './ConfirmEmailSent';
import DoYouHaveAnDebitosAccount from './DoYouHaveAnDebitosAccount';
import styles from './index.css';
import type { LoginProps } from './types';
import zodLogin from './zod';

const LoginForm: FC<LoginProps> = ({
  csrf,
  lang,
  error,
  pathname,
  redirectQuery,
}) => {
  const { user } = useUser();
  const {
    components: {
      forms: {
        debitosIdOrEmail,
        emailAddress,
        forgotPassword,
        rememberID: rememberIDLabel,
        next,
        password,
        previous,
        login: loginTranslation,
        check2FA,
        validationFailed2FA,

        code2FA,
        verify2FA,
        invalid2FA,
        sso,
      },
    },
    pages: {
      login: { heading },
      sso: { manage, doYouHaveAnDebitosAccount, completeStatement },
    },
  } = useTranslations(lang);
  const { login2FACodeResolver } = zodLogin(lang);

  const {
    submitEmail,
    handleInput,
    handleCheckbox,
    submitPassword,
    handlePreviousStep,
    setErrors,
    setConfirmEmail,
    confirmEmail,
    currentStep,
    fields,
    errors,
    isLoading,
    login,
    loginStore,
  } = useLogin({
    lang,
  });

  const onSubmitToLogin = () => {
    toggleConnectSSO();
  };

  const resetLoginPage = () => {
    userStore.resetLogin();
  };

  const onSubmit2FACode = async (data: { code: string }) => {
    loginStore.setCodeErrors([]);
    loginStore.setIsLoading2FA(true);
    const verified = await userStore.verify2FACode(data.code);
    if (verified?.token === null) {
      loginStore.setIsLoading2FA(false);
      loginStore.setCodeErrors([invalid2FA]);
      return;
    }
    return (window.location.href = `${DEBITOS_MARKETPLACE}/Signin/token/${verified.token}`);
  };

  const toggleConnectSSO = () => {
    loginStore.toggleSSO('connect');
  };

  useEffect(() => {
    checkRedirectQuery(redirectQuery);

    const queryStrings = getAndReplaceUrlQuery();
    queryStrings.forEach((query) => {
      if (query.key === 'error' && query.value === '2fa') {
        setErrors([validationFailed2FA]);
      }
      if (query.key === 'error' && query.value === 'invalid-sso') {
        setErrors([sso.invalidSSO]);
      }
      if (query.key === 'error' && query.value === 'not-logged-in') {
        setErrors([sso.notLoggedIn]);
      }
      if (query.key === 'error' && query.value === 'invalid-cookie') {
        setErrors([sso.invalidCookie]);
      }
      if (query.key === 'error' && query.value === 'invalid-user') {
        setErrors([sso.invalidUser]);
      }
      if (query.key === 'error' && query.value === 'not-connected') {
        setErrors([sso.notConnected]);
      }
      if (query.key === 'error' && query.value === 'invalid-provider') {
        setErrors([sso.invalidProvider]);
      }
      if (query.key === 'action' && query.value === 'connected-provider') {
        setErrors([sso.ssoSuccessfulConnected]);
      }
      if (query.key === 'action' && query.value === 'user_cancelled_login') {
        setErrors(['Login cancelled by user input']);
      }
      if (
        query.key === 'error' &&
        query.value === 'invalid-confirmation-token'
      ) {
        setErrors([sso.invalidConfirmationToken]);
      }
      if (query.key === 'error' && query.value === 'error-processing-sso') {
        setErrors([sso.errorProcessingSSO]);
      }
      if (query.key === 'error' && query.value === 'sso-already-connected') {
        setErrors([sso.ssoAlreadyConnected]);
      }
      if (query.key === 'redirect') {
        setLocalStorage(query.key, encodeURI(query.value));
      }
      if (query.key === 'email') {
        setCookie(query.key, query.value, 1);
        setConfirmEmail(query.value);
      }
    });
  }, [error]);

  useEffect(() => {
    if (window !== undefined) {
      const email = getCookie('email');
      setConfirmEmail(email);
      invalidateCookie('email');
    }
  }, [window]);

  const getMainHeadingTitle = () => {
    if (login.sso === null && !login.confirmationEmailSent) {
      if (login.doYouHaveAnDebitosAccount) {
        return doYouHaveAnDebitosAccount;
      } else {
        return heading;
      }
    } else if (login.sso === 'connect') {
      return heading;
    } else if (login.sso === 'manage') {
      return manage.heading;
    } else if (login.confirmationEmailSent) {
      return sso.confirmEmailHeading;
    }

    return '';
  };

  const getMainHeadingSubtitle = () => {
    if (login.sso === null || login.sso === 'connect') {
      if (login.confirmationEmailSent) {
        return undefined;
      } else {
        return (
          <DontHaveAnAccount
            lang={lang}
            isDisabled={isLoading}
            dataTestId="signup-fromloginPage"
          />
        );
      }
    } else if (login.sso === 'manage') {
      return manage.subheading;
    } else {
      return undefined;
    }
  };

  return (
    <div className={styles.formContainer}>
      <MainHeadings
        title={getMainHeadingTitle()}
        subtitle={getMainHeadingSubtitle()}
        dataTestId="heading-login"
      />
      {login.doYouHaveAnDebitosAccount && (
        <DoYouHaveAnDebitosAccount lang={lang} />
      )}
      {login.confirmationEmailSent && (
        <ConfirmEmail lang={lang} confirmEmail={confirmEmail} />
      )}
      {!login.doYouHaveAnDebitosAccount && !login.confirmationEmailSent ? (
        <div className={styles.loginRegister}>
          {!user.required2FA && currentStep === 0 && (
            <>
              {login.sso === null && (
                <MultiInputForm onSubmit={submitEmail}>
                  <input type="hidden" id="_token" name="_token" value={csrf} />
                  <Input
                    name="email"
                    id="email"
                    type="email"
                    required
                    label={debitosIdOrEmail}
                    onChange={(e: any) => handleInput(e, 'email')}
                    data-testid="login-form-email-input"
                    placeholder={emailAddress}
                    value={fields.email?.value}
                    invalid={
                      typeof fields.email?.invalidText === 'undefined'
                        ? false
                        : true
                    }
                    invalidText={fields.email?.invalidText}
                    isDisabled={isLoading}
                  />
                  <Input
                    name="hidden"
                    id="hidden"
                    type="password"
                    label={password}
                    data-testid="login-form-hidden-password-input"
                    placeholder={password}
                    onChange={(e: any) => handleInput(e, 'hidden')}
                    isDisabled={isLoading}
                    hasSeparator={false}
                  />
                  <Checkbox
                    id="rememberId"
                    label={rememberIDLabel}
                    dataTestId="login-form-checkbox"
                    isChecked={fields.rememberMe?.value}
                    margin={1}
                    handleCheckbox={() => handleCheckbox('rememberMe')}
                    invalid={
                      typeof fields.rememberMe?.invalidText === 'undefined'
                        ? false
                        : true
                    }
                    invalidText={fields.rememberMe?.invalidText}
                  />

                  <SSOSmall
                    hasHeading={true}
                    lang={lang}
                    // @ts-ignore
                    isManageable={true}
                  />

                  <NeedHelp lang={lang} isDisabled={isLoading} />
                  <BottomButtons
                    submitLabel={next}
                    hasSubmitIcon
                    rightDataTestId="login-form-email-submit-button"
                  />
                  <ErrorMessages
                    errorMessages={errors}
                    dataTestId="login-form-submit-failed-error"
                  />
                </MultiInputForm>
              )}
            </>
          )}
          {!user.required2FA && currentStep === 1 && (
            <MultiInputForm onSubmit={submitPassword}>
              <Input
                name="password"
                id="password"
                type="password"
                required
                data-testid="login-form-password-input"
                placeholder={password}
                label={password}
                sideButton={
                  <InputSideButton
                    href={getBasePathname(lang, 'lost-password')}
                    label={forgotPassword}
                    isDisabled={isLoading}
                    dataTestId="login-form-forgot-password"
                  />
                }
                invalid={
                  typeof fields.password?.invalidText === 'undefined'
                    ? false
                    : true
                }
                invalidText={fields.password?.invalidText}
                value={fields.password?.value}
                onChange={(e: any) => handleInput(e, 'password')}
                isDisabled={isLoading}
                margin={1.5}
              />
              <NeedHelp lang={lang} isDisabled={isLoading} />
              <BottomButtons
                leftButtonLabel={previous}
                leftButtonIcon="back"
                leftButtonOnClick={handlePreviousStep}
                submitLabel={loginTranslation}
                hasSubmitIcon
                isLoading={isLoading}
                rightDataTestId="login-form-password-submit-button"
              />
              <ErrorMessages
                errorMessages={errors}
                dataTestId="login-form-submit-failed-error"
                id="login-form-submit-failed-error"
              />
            </MultiInputForm>
          )}
          {user.required2FA && (
            <Form onSubmit={onSubmit2FACode} resolver={login2FACodeResolver}>
              <p>{check2FA}</p>
              <Input
                name="code"
                id="code"
                type="number"
                required
                data-testid="login-form-2fa-input"
                placeholder={code2FA}
                label={code2FA}
                margin={1.3}
              />
              <NeedHelp lang={lang} isDisabled={isLoading} />
              <BottomButtons
                submitLabel={verify2FA}
                leftButtonLabel={previous}
                leftButtonIcon="back"
                leftButtonOnClick={resetLoginPage}
                hasSubmitIcon
                isLoading={login.isLoading2FA}
                rightDataTestId="2fa-verification-button"
              />
              <ErrorMessages
                dataTestId="2fa-error-message"
                errorMessages={login.codeErrors}
              />
            </Form>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default LoginForm;
