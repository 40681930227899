---
import '@fontsource/lato';
import { ViewTransitions } from 'astro:transitions';

import { BrandLogo, HexLogin, LogoWithoutClaim } from '@/assets';
import GDPR from '@/assets/gdpr.html';
import { HamburguerMenu, Links } from '@/components';
import { getLangFromUrl } from '@/i18n/utils';
import { Device, Redirect } from '@/layouts';
import { colorScheme } from '@/store';
import { darkThemeClass, lightThemeClass } from '@/styles/themes.css';


import {
bg,
brand,
brandContainer,
layout,
logo,
logoWithoutClaim,
logoWithoutClaimContainer,
main,
mainContent,
} from './index.css';
import styles from './index.module.scss';

const { url, props, cookies, redirect } = Astro;
const { title } = props;

const lang = getLangFromUrl(url);
const hasTitle = title.length > 0;
let colorSchemeClass = lightThemeClass;

switch (colorScheme.$colorScheme.get().value) {
  case 'dark': {
    colorSchemeClass = darkThemeClass;
    break;
  }
  case 'light': {
    colorSchemeClass = lightThemeClass;
    break;
  }
  default: {
    colorSchemeClass = lightThemeClass;
    break;
  }
}
---

<!DOCTYPE html>
<html lang={lang}>
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width" />
    <link rel="icon" type="image/png" href="/logo.png" />
    <meta name="generator" content={Astro.generator} />
    <title>Debitos&reg; {hasTitle ? `| ${title}` : null}</title>
    <GDPR />
    <ViewTransitions />
  </head>
  <body class:list={[layout, colorSchemeClass]}>
    <Redirect client:only="react" />
    <Device client:only="react" />
    <div class:list={[bg, styles.backgroundImage]}>
      <main class={main}>
        <aside
          class={brand}
          style={{
            backgroundImage: `url(${HexLogin.src})`,
          }}
        >
          <div class={brandContainer}>
            <img src={Object(BrandLogo).src} class={logo} alt="logo" />
          </div>
          <Links classType="linksContainerMobile" lang={lang} dataTestId='layout-links-list-mobile' />
        </aside>
        <aside class={mainContent}>
          <div class={logoWithoutClaimContainer}>
            <img src={Object(LogoWithoutClaim).src} class={logoWithoutClaim} alt="logo" />
            <HamburguerMenu client:load lang={lang} url={url} />
          </div>
          <slot />
        </aside>
        <Links classType="linksContainer" lang={lang} dataTestId='layout-links-list-desktop' />
      </main>
    </div>

    <style is:global>
      * {
        margin: 0;
        padding: 0;
        text-rendering: optimizeLegibility;
        font-family: 'Lato', Arial;
      }
      html,
      body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
      }
    </style>
  </body>
</html>
