import React, { FC, useState } from 'react';

import {
  BottomButtons,
  MainHeadings,
  NeedHelp,
  Separator,
} from '@/components/forms';
import { DEBITOS_MARKETPLACE } from '@/constants';
import { useTranslations } from '@/hooks';
import styles from './index.css';
import { ConfirmationMessageProps } from './types';

const ConfirmationMessage: FC<ConfirmationMessageProps> = ({ lang }) => {
  const { pages, components } = useTranslations(lang);
  const [errors, setErrors] = useState<string[]>([]);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hints = [
    components.forms.sso.inboxIsBusy,
    components.forms.sso.ourEmails,
    components.forms.sso.yourEmails,
    components.forms.sso.criticalError,
  ];

  return (
    <div className={styles.formContainer}>
      <MainHeadings title={components.forms.sso.confirmationSent} />

      <div>
        <Separator>
          <div className={styles.invalidTokenStatementContainer}>
            <p>{components.forms.sso.in15Minutes}</p>
            <p>{components.forms.sso.pleaseVerifyEmail}</p>
          </div>
        </Separator>
        <Separator>
          <div className={styles.listContainer}>
            <p>{components.forms.sso.ifCannotGetEmail}</p>
            <ul className={styles.list}>
              {hints.map((hint, index) => (
                <li key={index}>{hint}</li>
              ))}
            </ul>
          </div>
        </Separator>
      </div>
      <NeedHelp lang={lang} />
      <BottomButtons
        leftButtonLabel={components.forms.back}
        leftButtonIcon="back"
        leftButtonOnClick={() =>
          (window.location.href = DEBITOS_MARKETPLACE + '/my-account')
        }
      />
    </div>
  );
};

export default ConfirmationMessage;
