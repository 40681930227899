---
import '@fontsource/lato';
import { ViewTransitions } from 'astro:transitions';

import GDPR from '@/assets/gdpr.html';
import { Links } from '@/components';
import { getLangFromUrl } from '@/i18n/utils';
import { Device, Redirect } from '@/layouts';
import { colorScheme } from '@/store';
import { darkThemeClass, lightThemeClass } from '@/styles/themes.css';

import { bg, body, main } from './index.css';
import styles from './index.module.scss';

const { props, url } = Astro;
const { title } = props;

const lang = getLangFromUrl(url);

const hasTitle = title.length > 0;

let colorSchemeClass = lightThemeClass;

switch (colorScheme.$colorScheme.get().value) {
  case 'dark': {
    colorSchemeClass = darkThemeClass;
    break;
  }
  case 'light': {
    colorSchemeClass = lightThemeClass;
    break;
  }
  default: {
    colorSchemeClass = lightThemeClass;
    break;
  }
}

---

<html lang={lang}>
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width" />
    <link rel="icon" type="image/png" href="/logo.png" />
    <meta name="generator" content={Astro.generator} />
    <title>Debitos&reg; {hasTitle ? `| ${title}` : null}</title>
    <GDPR />
    <ViewTransitions />
  </head>
  <body class:list={[colorSchemeClass, body]} tabindex="-1" onfocus="blur()">
    <Redirect client:only="react" />
    <Device client:only />
    <div class:list={[bg, styles.backgroundImage]}>
      <main class={main}>
        <slot />
        <Links classType="linksContainer" lang={lang} />
      </main>
    </div>
    <style is:global>
      * {
        margin: 0;
        padding: 0;
        text-rendering: optimizeLegibility;
        font-family: 'Lato', Arial;
      }
      html,
      body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
      }
    </style>
  </body>
</html>
