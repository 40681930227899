import { MapStore, map } from 'nanostores';

import { LoginState, LoginStateSetter, SSO, Value } from './types';

class Login {
  $login: MapStore<LoginState> = map<LoginState>({
    sso: null,
    codeErrors: [],
    isLoading2FA: false,
    doYouHaveAnDebitosAccount: false,
    confirmationEmailSent: false,
  });

  private set({
    sso,
    codeErrors,
    isLoading2FA,
    doYouHaveAnDebitosAccount,
    confirmationEmailSent,
  }: LoginStateSetter) {
    const login = this.get();

    const localSso = sso || sso === null ? sso : login.sso;
    const localCodeErrors = codeErrors ?? login.codeErrors;
    const localIsLoading2FA = isLoading2FA ?? login.isLoading2FA;
    const localDoYouHaveAnDebitosAccount =
      doYouHaveAnDebitosAccount ?? login.doYouHaveAnDebitosAccount;
    const localConfirmationEmailSent =
      confirmationEmailSent ?? login.confirmationEmailSent;

    this.$login.set({
      sso: localSso,
      codeErrors: localCodeErrors,
      isLoading2FA: localIsLoading2FA,
      doYouHaveAnDebitosAccount: localDoYouHaveAnDebitosAccount,
      confirmationEmailSent: localConfirmationEmailSent,
    });
  }

  private get() {
    return this.$login.get();
  }

  setValue(value: Value) {
    this.set(value);
  }

  toggleSSO(sso: SSO) {
    this.set({
      sso: sso?.includes(this.get().sso as string) ? null : sso,
    });
  }

  setCodeErrors(errors: string[]) {
    this.set({ codeErrors: errors });
  }

  setIsLoading2FA(isLoading: boolean) {
    this.set({ isLoading2FA: isLoading });
  }

  setDoYouHaveAnDebitosAccount(doYouHaveAnDebitosAccount: boolean) {
    this.set({
      doYouHaveAnDebitosAccount,
      sso: doYouHaveAnDebitosAccount ? null : undefined,
    });
  }
}

export default new Login();
