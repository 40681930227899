import useTranslations from '@/hooks/useTranslations';
import { UseCountriesProps } from './types';

const useCountries = (lang: UseCountriesProps) => {
  const {
    components: {
      forms: { countries: countriesTranslation },
    },
  } = useTranslations(lang);

  const countriesToPreselect = [
    {
      label: countriesTranslation.andorra,
      filterLabel: 'Andorra',
      value: 'AD',
      phoneCountryCode: '+376',
      phoneCountryCodeISO3166: 'AND',
    },
    {
      label: countriesTranslation.unitedArabEmirates,
      filterLabel: 'United Arab Emirates',
      value: 'AE',
      phoneCountryCode: '+971',
      phoneCountryCodeISO3166: 'ARE',
    },
    {
      label: countriesTranslation.afghanistan,
      filterLabel: 'Afghanistan',
      value: 'AF',
      phoneCountryCode: '+93',
      phoneCountryCodeISO3166: 'AFG',
    },
    {
      label: countriesTranslation.antiguaAndBarbuda,
      filterLabel: 'Antigua and Barbuda',
      value: 'AG',
      phoneCountryCode: '+1268',
      phoneCountryCodeISO3166: 'ATG',
    },
    {
      label: countriesTranslation.anguilla,
      filterLabel: 'Anguilla',
      value: 'AI',
      phoneCountryCode: '+1264',
      phoneCountryCodeISO3166: 'AIA',
    },
    {
      label: countriesTranslation.albania,
      filterLabel: 'Albania',
      value: 'AL',
      phoneCountryCode: '+355',
      phoneCountryCodeISO3166: 'ALB',
    },
    {
      label: countriesTranslation.armenia,
      filterLabel: 'Armenia',
      value: 'AM',
      phoneCountryCode: '+374',
      phoneCountryCodeISO3166: 'ARM',
    },
    {
      label: countriesTranslation.angola,
      filterLabel: 'Angola',
      value: 'AO',
      phoneCountryCode: '+244',
      phoneCountryCodeISO3166: 'AGO',
    },
    {
      label: countriesTranslation.antarctica,
      filterLabel: 'Antarctica',
      value: 'AQ',
      phoneCountryCode: '+672',
      phoneCountryCodeISO3166: 'ATA',
    },
    {
      label: countriesTranslation.argentina,
      filterLabel: 'Argentina',
      value: 'AR',
      phoneCountryCode: '+54',
      phoneCountryCodeISO3166: 'ARG',
    },
    {
      label: countriesTranslation.americanSamoa,
      filterLabel: 'American Samoa',
      value: 'AS',
      phoneCountryCode: '+1684',
      phoneCountryCodeISO3166: 'ASM',
    },
    {
      label: countriesTranslation.austria,
      filterLabel: 'Austria',
      value: 'AT',
      phoneCountryCode: '+43',
      phoneCountryCodeISO3166: 'AUT',
    },
    {
      label: countriesTranslation.australia,
      filterLabel: 'Australia',
      value: 'AU',
      phoneCountryCode: '+61',
      phoneCountryCodeISO3166: 'AUS',
    },
    {
      label: countriesTranslation.aruba,
      filterLabel: 'Aruba',
      value: 'AW',
      phoneCountryCode: '+297',
      phoneCountryCodeISO3166: 'ABW',
    },
    {
      label: countriesTranslation.aland,
      filterLabel: 'Aland',
      value: 'AX',
      phoneCountryCode: '+358',
      phoneCountryCodeISO3166: 'ALA',
    },
    {
      label: countriesTranslation.azerbaijan,
      filterLabel: 'Azerbaijan',
      value: 'AZ',
      phoneCountryCode: '+994',
      phoneCountryCodeISO3166: 'AZE',
    },
    {
      label: countriesTranslation.bosniaAndHerzegovina,
      filterLabel: 'Bosnia and Herzegovina',
      value: 'BA',
      phoneCountryCode: '+387',
      phoneCountryCodeISO3166: 'BIH',
    },
    {
      label: countriesTranslation.barbados,
      filterLabel: 'Barbados',
      value: 'BB',
      phoneCountryCode: '+1246',
      phoneCountryCodeISO3166: 'BRB',
    },
    {
      label: countriesTranslation.bangladesh,
      filterLabel: 'Bangladesh',
      value: 'BD',
      phoneCountryCode: '+880',
      phoneCountryCodeISO3166: 'BGD',
    },
    {
      label: countriesTranslation.belgium,
      filterLabel: 'Belgium',
      value: 'BE',
      phoneCountryCode: '+32',
      phoneCountryCodeISO3166: 'BEL',
    },
    {
      label: countriesTranslation.burkinaFaso,
      filterLabel: 'Burkina Faso',
      value: 'BF',
      phoneCountryCode: '+226',
      phoneCountryCodeISO3166: 'BFA',
    },
    {
      label: countriesTranslation.bulgaria,
      filterLabel: 'Bulgaria',
      value: 'BG',
      phoneCountryCode: '+359',
      phoneCountryCodeISO3166: 'BGR',
    },
    {
      label: countriesTranslation.bahrain,
      filterLabel: 'Bahrain',
      value: 'BH',
      phoneCountryCode: '+973',
      phoneCountryCodeISO3166: 'BHR',
    },
    {
      label: countriesTranslation.burundi,
      filterLabel: 'Burundi',
      value: 'BI',
      phoneCountryCode: '+257',
      phoneCountryCodeISO3166: 'BDI',
    },
    {
      label: countriesTranslation.benin,
      filterLabel: 'Benin',
      value: 'BJ',
      phoneCountryCode: '+229',
      phoneCountryCodeISO3166: 'BEN',
    },
    {
      label: countriesTranslation.saintBarthelemy,
      filterLabel: 'Saint Barthelemy',
      value: 'BL',
      phoneCountryCode: '+590',
      phoneCountryCodeISO3166: 'BLM',
    },
    {
      label: countriesTranslation.bermuda,
      filterLabel: 'Bermuda',
      value: 'BM',
      phoneCountryCode: '+1441',
      phoneCountryCodeISO3166: 'BMU',
    },
    {
      label: countriesTranslation.brunei,
      filterLabel: 'Brunei',
      value: 'BN',
      phoneCountryCode: '+673',
      phoneCountryCodeISO3166: 'BRN',
    },
    {
      label: countriesTranslation.bolivia,
      filterLabel: 'Bolivia',
      value: 'BO',
      phoneCountryCode: '+591',
      phoneCountryCodeISO3166: 'BOL',
    },
    {
      label: countriesTranslation.bonaire,
      filterLabel: 'Bonaire',
      value: 'BQ',
      phoneCountryCode: '+5997',
      phoneCountryCodeISO3166: 'BES',
    },
    {
      label: countriesTranslation.brazil,
      filterLabel: 'Brazil',
      value: 'BR',
      phoneCountryCode: '+55',
      phoneCountryCodeISO3166: 'BRA',
    },
    {
      label: countriesTranslation.bahamas,
      filterLabel: 'Bahamas',
      value: 'BS',
      phoneCountryCode: '+1242',
      phoneCountryCodeISO3166: 'BHS',
    },
    {
      label: countriesTranslation.bhutan,
      filterLabel: 'Bhutan',
      value: 'BT',
      phoneCountryCode: '+975',
      phoneCountryCodeISO3166: 'BTN',
    },
    {
      label: countriesTranslation.bouvetIsland,
      filterLabel: 'Bouvet Island',
      value: 'BV',
      phoneCountryCode: '+47',
      phoneCountryCodeISO3166: 'BVT',
    },
    {
      label: countriesTranslation.botswana,
      filterLabel: 'Botswana',
      value: 'BW',
      phoneCountryCode: '+267',
      phoneCountryCodeISO3166: 'BWA',
    },
    {
      label: countriesTranslation.belarus,
      filterLabel: 'Belarus',
      value: 'BY',
      phoneCountryCode: '+375',
      phoneCountryCodeISO3166: 'BLR',
    },
    {
      label: countriesTranslation.belize,
      filterLabel: 'Belize',
      value: 'BZ',
      phoneCountryCode: '+501',
      phoneCountryCodeISO3166: 'BLZ',
    },
    {
      label: countriesTranslation.canada,
      filterLabel: 'Canada',
      value: 'CA',
      phoneCountryCode: '+1',
      phoneCountryCodeISO3166: 'CAN',
    },
    {
      label: countriesTranslation.cocosKeelingIslands,
      filterLabel: 'Cocos (Keeling) Islands',
      value: 'CC',
      phoneCountryCode: '+61',
      phoneCountryCodeISO3166: 'CCK',
    },
    {
      label: countriesTranslation.democraticRepublicOfTheCongo,
      filterLabel: 'Democratic Republic of the Congo',
      value: 'CD',
      phoneCountryCode: '+243',
      phoneCountryCodeISO3166: 'COD',
    },
    {
      label: countriesTranslation.centralAfricanRepublic,
      filterLabel: 'Central African Republic',
      value: 'CF',
      phoneCountryCode: '+236',
      phoneCountryCodeISO3166: 'CAF',
    },
    {
      label: countriesTranslation.republicOfTheCongo,
      filterLabel: 'Republic of the Congo',
      value: 'CG',
      phoneCountryCode: '+242',
      phoneCountryCodeISO3166: 'COG',
    },
    {
      label: countriesTranslation.switzerland,
      filterLabel: 'Switzerland',
      value: 'CH',
      phoneCountryCode: '+41',
      phoneCountryCodeISO3166: 'CHE',
    },
    {
      label: countriesTranslation.ivoryCoast,
      filterLabel: 'Ivory Coast',
      value: 'CI',
      phoneCountryCode: '+225',
      phoneCountryCodeISO3166: 'CIV',
    },
    {
      label: countriesTranslation.cookIslands,
      filterLabel: 'Cook Islands',
      value: 'CK',
      phoneCountryCode: '+682',
      phoneCountryCodeISO3166: 'COK',
    },
    {
      label: countriesTranslation.chile,
      filterLabel: 'Chile',
      value: 'CL',
      phoneCountryCode: '+56',
      phoneCountryCodeISO3166: 'CHL',
    },
    {
      label: countriesTranslation.cameroon,
      filterLabel: 'Cameroon',
      value: 'CM',
      phoneCountryCode: '+237',
      phoneCountryCodeISO3166: 'CMR',
    },
    {
      label: countriesTranslation.china,
      filterLabel: 'China',
      value: 'CN',
      phoneCountryCode: '+86',
      phoneCountryCodeISO3166: 'CHN',
    },
    {
      label: countriesTranslation.colombia,
      filterLabel: 'Colombia',
      value: 'CO',
      phoneCountryCode: '+57',
      phoneCountryCodeISO3166: 'COL',
    },
    {
      label: countriesTranslation.costaRica,
      filterLabel: 'Costa Rica',
      value: 'CR',
      phoneCountryCode: '+506',
      phoneCountryCodeISO3166: 'CRI',
    },
    {
      label: countriesTranslation.cuba,
      filterLabel: 'Cuba',
      value: 'CU',
      phoneCountryCode: '+53',
      phoneCountryCodeISO3166: 'CUB',
    },
    {
      label: countriesTranslation.capeVerde,
      filterLabel: 'Cape Verde',
      value: 'CV',
      phoneCountryCode: '+238',
      phoneCountryCodeISO3166: 'CPV',
    },
    {
      label: countriesTranslation.curacao,
      filterLabel: 'Curacao',
      value: 'CW',
      phoneCountryCode: '+5999',
      phoneCountryCodeISO3166: 'CUW',
    },
    {
      label: countriesTranslation.christmasIsland,
      filterLabel: 'Christmas Island',
      value: 'CX',
      phoneCountryCode: '+61',
      phoneCountryCodeISO3166: 'CXR',
    },
    {
      label: countriesTranslation.cyprus,
      filterLabel: 'Cyprus',
      value: 'CY',
      phoneCountryCode: '+357',
      phoneCountryCodeISO3166: 'CYP',
    },
    {
      label: countriesTranslation.czechRepublic,
      filterLabel: 'Czech Republic',
      value: 'CZ',
      phoneCountryCode: '+420',
      phoneCountryCodeISO3166: 'CZE',
    },
    {
      label: countriesTranslation.germany,
      filterLabel: 'Germany',
      value: 'DE',
      phoneCountryCode: '+49',
      phoneCountryCodeISO3166: 'DEU',
    },
    {
      label: countriesTranslation.djibouti,
      filterLabel: 'Djibouti',
      value: 'DJ',
      phoneCountryCode: '+253',
      phoneCountryCodeISO3166: 'DJI',
    },
    {
      label: countriesTranslation.denmark,
      filterLabel: 'Denmark',
      value: 'DK',
      phoneCountryCode: '+45',
      phoneCountryCodeISO3166: 'DNK',
    },
    {
      label: countriesTranslation.dominica,
      filterLabel: 'Dominica',
      value: 'DM',
      phoneCountryCode: '+1767',
      phoneCountryCodeISO3166: 'DMA',
    },
    {
      label: countriesTranslation.dominicanRepublic,
      filterLabel: 'Dominican Republic',
      value: 'DO',
      phoneCountryCode: '+1809',
      phoneCountryCodeISO3166: 'DOM',
    },
    {
      label: countriesTranslation.algeria,
      filterLabel: 'Algeria',
      value: 'DZ',
      phoneCountryCode: '+213',
      phoneCountryCodeISO3166: 'DZA',
    },
    {
      label: countriesTranslation.ecuador,
      filterLabel: 'Ecuador',
      value: 'EC',
      phoneCountryCode: '+593',
      phoneCountryCodeISO3166: 'ECU',
    },
    {
      label: countriesTranslation.estonia,
      filterLabel: 'Estonia',
      value: 'EE',
      phoneCountryCode: '+372',
      phoneCountryCodeISO3166: 'EST',
    },
    {
      label: countriesTranslation.egypt,
      filterLabel: 'Egypt',
      value: 'EG',
      phoneCountryCode: '+20',
      phoneCountryCodeISO3166: 'EGY',
    },
    {
      label: countriesTranslation.westernSahara,
      filterLabel: 'Western Sahara',
      value: 'EH',
      phoneCountryCode: '+212',
      phoneCountryCodeISO3166: 'ESH',
    },
    {
      label: countriesTranslation.eritrea,
      filterLabel: 'Eritrea',
      value: 'ER',
      phoneCountryCode: '+291',
      phoneCountryCodeISO3166: 'ERI',
    },
    {
      label: countriesTranslation.spain,
      filterLabel: 'Spain',
      value: 'ES',
      phoneCountryCode: '+34',
      phoneCountryCodeISO3166: 'ESP',
    },
    {
      label: countriesTranslation.ethiopia,
      filterLabel: 'Ethiopia',
      value: 'ET',
      phoneCountryCode: '+251',
      phoneCountryCodeISO3166: 'ETH',
    },
    {
      label: countriesTranslation.finland,
      filterLabel: 'Finland',
      value: 'FI',
      phoneCountryCode: '+358',
      phoneCountryCodeISO3166: 'FIN',
    },
    {
      label: countriesTranslation.fiji,
      filterLabel: 'Fiji',
      value: 'FJ',
      phoneCountryCode: '+679',
      phoneCountryCodeISO3166: 'FJI',
    },
    {
      label: countriesTranslation.falklandIslands,
      filterLabel: 'Falkland Islands',
      value: 'FK',
      phoneCountryCode: '+500',
      phoneCountryCodeISO3166: 'FLK',
    },
    {
      label: countriesTranslation.micronesia,
      filterLabel: 'Micronesia',
      value: 'FM',
      phoneCountryCode: '+691',
      phoneCountryCodeISO3166: 'FSM',
    },
    {
      label: countriesTranslation.faroeIslands,
      filterLabel: 'Faroe Islands',
      value: 'FO',
      phoneCountryCode: '+298',
      phoneCountryCodeISO3166: 'FRO',
    },
    {
      label: countriesTranslation.france,
      filterLabel: 'France',
      value: 'FR',
      phoneCountryCode: '+33',
      phoneCountryCodeISO3166: 'FRA',
    },
    {
      label: countriesTranslation.gabon,
      filterLabel: 'Gabon',
      value: 'GA',
      phoneCountryCode: '+241',
      phoneCountryCodeISO3166: 'GAB',
    },
    {
      label: countriesTranslation.unitedKingdom,
      filterLabel: 'United Kingdom',
      value: 'GB',
      phoneCountryCode: '+44',
      phoneCountryCodeISO3166: 'GBR',
    },
    {
      label: countriesTranslation.grenada,
      filterLabel: 'Grenada',
      value: 'GD',
      phoneCountryCode: '+1473',
      phoneCountryCodeISO3166: 'GRD',
    },
    {
      label: countriesTranslation.georgia,
      filterLabel: 'Georgia',
      value: 'GE',
      phoneCountryCode: '+995',
      phoneCountryCodeISO3166: 'GEO',
    },
    {
      label: countriesTranslation.frenchGuiana,
      filterLabel: 'French Guiana',
      value: 'GF',
      phoneCountryCode: '+594',
      phoneCountryCodeISO3166: 'GUF',
    },
    {
      label: countriesTranslation.guernsey,
      filterLabel: 'Guernsey',
      value: 'GG',
      phoneCountryCode: '+44',
      phoneCountryCodeISO3166: 'GGY',
    },
    {
      label: countriesTranslation.ghana,
      filterLabel: 'Ghana',
      value: 'GH',
      phoneCountryCode: '+233',
      phoneCountryCodeISO3166: 'GHA',
    },
    {
      label: countriesTranslation.gibraltar,
      filterLabel: 'Gibraltar',
      value: 'GI',
      phoneCountryCode: '+350',
      phoneCountryCodeISO3166: 'GIB',
    },
    {
      label: countriesTranslation.greenland,
      filterLabel: 'Greenland',
      value: 'GL',
      phoneCountryCode: '+299',
      phoneCountryCodeISO3166: 'GRL',
    },
    {
      label: countriesTranslation.gambia,
      filterLabel: 'Gambia',
      value: 'GM',
      phoneCountryCode: '+220',
      phoneCountryCodeISO3166: 'GMB',
    },
    {
      label: countriesTranslation.guinea,
      filterLabel: 'Guinea',
      value: 'GN',
      phoneCountryCode: '+224',
      phoneCountryCodeISO3166: 'GIN',
    },
    {
      label: countriesTranslation.guadeloupe,
      filterLabel: 'Guadeloupe',
      value: 'GP',
      phoneCountryCode: '+590',
      phoneCountryCodeISO3166: 'GLP',
    },
    {
      label: countriesTranslation.equatorialGuinea,
      filterLabel: 'Equatorial Guinea',
      value: 'GQ',
      phoneCountryCode: '+240',
      phoneCountryCodeISO3166: 'GNQ',
    },
    {
      label: countriesTranslation.greece,
      filterLabel: 'Greece',
      value: 'GR',
      phoneCountryCode: '+30',
      phoneCountryCodeISO3166: 'GRC',
    },
    {
      label: countriesTranslation.southGeorgiaAndTheSouthSandwichIslands,
      filterLabel: 'South Georgia and the South Sandwich Islands',
      value: 'GS',
      phoneCountryCode: '+500',
      phoneCountryCodeISO3166: 'SGS',
    },
    {
      label: countriesTranslation.guatemala,
      filterLabel: 'Guatemala',
      value: 'GT',
      phoneCountryCode: '+502',
      phoneCountryCodeISO3166: 'GTM',
    },
    {
      label: countriesTranslation.guam,
      filterLabel: 'Guam',
      value: 'GU',
      phoneCountryCode: '+1671',
      phoneCountryCodeISO3166: 'GUM',
    },
    {
      label: countriesTranslation.guineaBissau,
      filterLabel: 'Guinea-Bissau',
      value: 'GW',
      phoneCountryCode: '+245',
      phoneCountryCodeISO3166: 'GNB',
    },
    {
      label: countriesTranslation.guyana,
      filterLabel: 'Guyana',
      value: 'GY',
      phoneCountryCode: '+592',
      phoneCountryCodeISO3166: 'GUY',
    },
    {
      label: countriesTranslation.hongKong,
      filterLabel: 'Hong Kong',
      value: 'HK',
      phoneCountryCode: '+852',
      phoneCountryCodeISO3166: 'HKG',
    },
    {
      label: countriesTranslation.heardIslandAndMcdonaldIslands,
      filterLabel: 'Heard Island and McDonald Islands',
      value: 'HM',
      phoneCountryCode: '+61',
      phoneCountryCodeISO3166: 'HMD',
    },
    {
      label: countriesTranslation.honduras,
      filterLabel: 'Honduras',
      value: 'HN',
      phoneCountryCode: '+504',
      phoneCountryCodeISO3166: 'HND',
    },
    {
      label: countriesTranslation.croatia,
      filterLabel: 'Croatia',
      value: 'HR',
      phoneCountryCode: '+385',
      phoneCountryCodeISO3166: 'HRV',
    },
    {
      label: countriesTranslation.haiti,
      filterLabel: 'Haiti',
      value: 'HT',
      phoneCountryCode: '+509',
      phoneCountryCodeISO3166: 'HTI',
    },
    {
      label: countriesTranslation.hungary,
      filterLabel: 'Hungary',
      value: 'HU',
      phoneCountryCode: '+36',
      phoneCountryCodeISO3166: 'HUN',
    },
    {
      label: countriesTranslation.indonesia,
      filterLabel: 'Indonesia',
      value: 'ID',
      phoneCountryCode: '+62',
      phoneCountryCodeISO3166: 'IDN',
    },
    {
      label: countriesTranslation.ireland,
      filterLabel: 'Ireland',
      value: 'IE',
      phoneCountryCode: '+353',
      phoneCountryCodeISO3166: 'IRL',
    },
    {
      label: countriesTranslation.israel,
      filterLabel: 'Israel',
      value: 'IL',
      phoneCountryCode: '+972',
      phoneCountryCodeISO3166: 'ISR',
    },
    {
      label: countriesTranslation.isleOfMan,
      filterLabel: 'Isle of Man',
      value: 'IM',
      phoneCountryCode: '+44',
      phoneCountryCodeISO3166: 'IMN',
    },
    {
      label: countriesTranslation.india,
      filterLabel: 'India',
      value: 'IN',
      phoneCountryCode: '+91',
      phoneCountryCodeISO3166: 'IND',
    },
    {
      label: countriesTranslation.britishIndianOceanTerritory,
      filterLabel: 'British Indian Ocean Territory',
      value: 'IO',
      phoneCountryCode: '+246',
      phoneCountryCodeISO3166: 'IOT',
    },
    {
      label: countriesTranslation.iraq,
      filterLabel: 'Iraq',
      value: 'IQ',
      phoneCountryCode: '+964',
      phoneCountryCodeISO3166: 'IRQ',
    },
    {
      label: countriesTranslation.iran,
      filterLabel: 'Iran',
      value: 'IR',
      phoneCountryCode: '+98',
      phoneCountryCodeISO3166: 'IRN',
    },
    {
      label: countriesTranslation.iceland,
      filterLabel: 'Iceland',
      value: 'IS',
      phoneCountryCode: '+354',
      phoneCountryCodeISO3166: 'ISL',
    },
    {
      label: countriesTranslation.italy,
      filterLabel: 'Italy',
      value: 'IT',
      phoneCountryCode: '+39',
      phoneCountryCodeISO3166: 'ITA',
    },
    {
      label: countriesTranslation.jersey,
      filterLabel: 'Jersey',
      value: 'JE',
      phoneCountryCode: '+44',
      phoneCountryCodeISO3166: 'JEY',
    },
    {
      label: countriesTranslation.jamaica,
      filterLabel: 'Jamaica',
      value: 'JM',
      phoneCountryCode: '+1876',
      phoneCountryCodeISO3166: 'JAM',
    },
    {
      label: countriesTranslation.jordan,
      filterLabel: 'Jordan',
      value: 'JO',
      phoneCountryCode: '+962',
      phoneCountryCodeISO3166: 'JOR',
    },
    {
      label: countriesTranslation.japan,
      filterLabel: 'Japan',
      value: 'JP',
      phoneCountryCode: '+81',
      phoneCountryCodeISO3166: 'JPN',
    },
    {
      label: countriesTranslation.kenya,
      filterLabel: 'Kenya',
      value: 'KE',
      phoneCountryCode: '+254',
      phoneCountryCodeISO3166: 'KEN',
    },
    {
      label: countriesTranslation.kyrgyzstan,
      filterLabel: 'Kyrgyzstan',
      value: 'KG',
      phoneCountryCode: '+996',
      phoneCountryCodeISO3166: 'KGZ',
    },
    {
      label: countriesTranslation.cambodia,
      filterLabel: 'Cambodia',
      value: 'KH',
      phoneCountryCode: '+855',
      phoneCountryCodeISO3166: 'KHM',
    },
    {
      label: countriesTranslation.kiribati,
      filterLabel: 'Kiribati',
      value: 'KI',
      phoneCountryCode: '+686',
      phoneCountryCodeISO3166: 'KIR',
    },
    {
      label: countriesTranslation.comoros,
      filterLabel: 'Comoros',
      value: 'KM',
      phoneCountryCode: '+269',
      phoneCountryCodeISO3166: 'COM',
    },
    {
      label: countriesTranslation.saintKittsAndNevis,
      filterLabel: 'Saint Kitts and Nevis',
      value: 'KN',
      phoneCountryCode: '+1869',
      phoneCountryCodeISO3166: 'KNA',
    },
    {
      label: countriesTranslation.northKorea,
      filterLabel: 'North Korea',
      value: 'KP',
      phoneCountryCode: '+850',
      phoneCountryCodeISO3166: 'PRK',
    },
    {
      label: countriesTranslation.southKorea,
      filterLabel: 'South Korea',
      value: 'KR',
      phoneCountryCode: '+82',
      phoneCountryCodeISO3166: 'KOR',
    },
    {
      label: countriesTranslation.kuwait,
      filterLabel: 'Kuwait',
      value: 'KW',
      phoneCountryCode: '+965',
      phoneCountryCodeISO3166: 'KWT',
    },
    {
      label: countriesTranslation.caymanIslands,
      filterLabel: 'Cayman Islands',
      value: 'KY',
      phoneCountryCode: '+1345',
      phoneCountryCodeISO3166: 'CYM',
    },
    {
      label: countriesTranslation.kazakhstan,
      filterLabel: 'Kazakhstan',
      value: 'KZ',
      phoneCountryCode: '+76',
      phoneCountryCodeISO3166: 'KAZ',
    },
    {
      label: countriesTranslation.laos,
      filterLabel: 'Laos',
      value: 'LA',
      phoneCountryCode: '+856',
      phoneCountryCodeISO3166: 'LAO',
    },
    {
      label: countriesTranslation.lebanon,
      filterLabel: 'Lebanon',
      value: 'LB',
      phoneCountryCode: '+961',
      phoneCountryCodeISO3166: 'LBN',
    },
    {
      label: countriesTranslation.saintLucia,
      filterLabel: 'Saint Lucia',
      value: 'LC',
      phoneCountryCode: '+1758',
      phoneCountryCodeISO3166: 'LCA',
    },
    {
      label: countriesTranslation.liechtenstein,
      filterLabel: 'Liechtenstein',
      value: 'LI',
      phoneCountryCode: '+423',
      phoneCountryCodeISO3166: 'LIE',
    },
    {
      label: countriesTranslation.sriLanka,
      filterLabel: 'Sri Lanka',
      value: 'LK',
      phoneCountryCode: '+94',
      phoneCountryCodeISO3166: 'LKA',
    },
    {
      label: countriesTranslation.liberia,
      filterLabel: 'Liberia',
      value: 'LR',
      phoneCountryCode: '+231',
      phoneCountryCodeISO3166: 'LBR',
    },
    {
      label: countriesTranslation.lesotho,
      filterLabel: 'Lesotho',
      value: 'LS',
      phoneCountryCode: '+266',
      phoneCountryCodeISO3166: 'LSO',
    },
    {
      label: countriesTranslation.lithuania,
      filterLabel: 'Lithuania',
      value: 'LT',
      phoneCountryCode: '+370',
      phoneCountryCodeISO3166: 'LTU',
    },
    {
      label: countriesTranslation.luxembourg,
      filterLabel: 'Luxembourg',
      value: 'LU',
      phoneCountryCode: '+352',
      phoneCountryCodeISO3166: 'LUX',
    },
    {
      label: countriesTranslation.latvia,
      filterLabel: 'Latvia',
      value: 'LV',
      phoneCountryCode: '+371',
      phoneCountryCodeISO3166: 'LVA',
    },
    {
      label: countriesTranslation.libya,
      filterLabel: 'Libya',
      value: 'LY',
      phoneCountryCode: '+218',
      phoneCountryCodeISO3166: 'LBY',
    },
    {
      label: countriesTranslation.morocco,
      filterLabel: 'Morocco',
      value: 'MA',
      phoneCountryCode: '+212',
      phoneCountryCodeISO3166: 'MAR',
    },
    {
      label: countriesTranslation.monaco,
      filterLabel: 'Monaco',
      value: 'MC',
      phoneCountryCode: '+377',
      phoneCountryCodeISO3166: 'MCO',
    },
    {
      label: countriesTranslation.moldova,
      filterLabel: 'Moldova',
      value: 'MD',
      phoneCountryCode: '+373',
      phoneCountryCodeISO3166: 'MDA',
    },
    {
      label: countriesTranslation.montenegro,
      filterLabel: 'Montenegro',
      value: 'ME',
      phoneCountryCode: '+382',
      phoneCountryCodeISO3166: 'MNE',
    },
    {
      label: countriesTranslation.saintMartin,
      filterLabel: 'Saint Martin',
      value: 'MF',
      phoneCountryCode: '+590',
      phoneCountryCodeISO3166: 'MAF',
    },
    {
      label: countriesTranslation.madagascar,
      filterLabel: 'Madagascar',
      value: 'MG',
      phoneCountryCode: '+261',
      phoneCountryCodeISO3166: 'MDG',
    },
    {
      label: countriesTranslation.marshallIslands,
      filterLabel: 'Marshall Islands',
      value: 'MH',
      phoneCountryCode: '+692',
      phoneCountryCodeISO3166: 'MHL',
    },
    {
      label: countriesTranslation.northMacedonia,
      filterLabel: 'North Macedonia',
      value: 'MK',
      phoneCountryCode: '+389',
      phoneCountryCodeISO3166: 'MKD',
    },
    {
      label: countriesTranslation.mali,
      filterLabel: 'Mali',
      value: 'ML',
      phoneCountryCode: '+223',
      phoneCountryCodeISO3166: 'MLI',
    },
    {
      label: countriesTranslation.myanmarBurma,
      filterLabel: 'Myanmar (Burma)',
      value: 'MM',
      phoneCountryCode: '+95',
      phoneCountryCodeISO3166: 'MMR',
    },
    {
      label: countriesTranslation.mongolia,
      filterLabel: 'Mongolia',
      value: 'MN',
      phoneCountryCode: '+976',
      phoneCountryCodeISO3166: 'MNG',
    },
    {
      label: countriesTranslation.macao,
      filterLabel: 'Macao',
      value: 'MO',
      phoneCountryCode: '+853',
      phoneCountryCodeISO3166: 'MAC',
    },
    {
      label: countriesTranslation.northernMarianaIslands,
      filterLabel: 'Northern Mariana Islands',
      value: 'MP',
      phoneCountryCode: '+1670',
      phoneCountryCodeISO3166: 'MNP',
    },
    {
      label: countriesTranslation.martinique,
      filterLabel: 'Martinique',
      value: 'MQ',
      phoneCountryCode: '+596',
      phoneCountryCodeISO3166: 'MTQ',
    },
    {
      label: countriesTranslation.mauritania,
      filterLabel: 'Mauritania',
      value: 'MR',
      phoneCountryCode: '+222',
      phoneCountryCodeISO3166: 'MRT',
    },
    {
      label: countriesTranslation.montserrat,
      filterLabel: 'Montserrat',
      value: 'MS',
      phoneCountryCode: '+1664',
      phoneCountryCodeISO3166: 'MSR',
    },
    {
      label: countriesTranslation.malta,
      filterLabel: 'Malta',
      value: 'MT',
      phoneCountryCode: '+356',
      phoneCountryCodeISO3166: 'MLT',
    },
    {
      label: countriesTranslation.mauritius,
      filterLabel: 'Mauritius',
      value: 'MU',
      phoneCountryCode: '+230',
      phoneCountryCodeISO3166: 'MUS',
    },
    {
      label: countriesTranslation.maldives,
      filterLabel: 'Maldives',
      value: 'MV',
      phoneCountryCode: '+960',
      phoneCountryCodeISO3166: 'MDV',
    },
    {
      label: countriesTranslation.malawi,
      filterLabel: 'Malawi',
      value: 'MW',
      phoneCountryCode: '+265',
      phoneCountryCodeISO3166: 'MWI',
    },
    {
      label: countriesTranslation.mexico,
      filterLabel: 'Mexico',
      value: 'MX',
      phoneCountryCode: '+52',
      phoneCountryCodeISO3166: 'MEX',
    },
    {
      label: countriesTranslation.malaysia,
      filterLabel: 'Malaysia',
      value: 'MY',
      phoneCountryCode: '+60',
      phoneCountryCodeISO3166: 'MYS',
    },
    {
      label: countriesTranslation.mozambique,
      filterLabel: 'Mozambique',
      value: 'MZ',
      phoneCountryCode: '+258',
      phoneCountryCodeISO3166: 'MOZ',
    },
    {
      label: countriesTranslation.namibia,
      filterLabel: 'Namibia',
      value: 'NA',
      phoneCountryCode: '+264',
      phoneCountryCodeISO3166: 'NAM',
    },
    {
      label: countriesTranslation.newCaledonia,
      filterLabel: 'New Caledonia',
      value: 'NC',
      phoneCountryCode: '+687',
      phoneCountryCodeISO3166: 'NCL',
    },
    {
      label: countriesTranslation.niger,
      filterLabel: 'Niger',
      value: 'NE',
      phoneCountryCode: '+227',
      phoneCountryCodeISO3166: 'NER',
    },
    {
      label: countriesTranslation.norfolkIsland,
      filterLabel: 'Norfolk Island',
      value: 'NF',
      phoneCountryCode: '+672',
      phoneCountryCodeISO3166: 'NFK',
    },
    {
      label: countriesTranslation.nigeria,
      filterLabel: 'Nigeria',
      value: 'NG',
      phoneCountryCode: '+234',
      phoneCountryCodeISO3166: 'NGA',
    },
    {
      label: countriesTranslation.nicaragua,
      filterLabel: 'Nicaragua',
      value: 'NI',
      phoneCountryCode: '+505',
      phoneCountryCodeISO3166: 'NIC',
    },
    {
      label: countriesTranslation.netherlands,
      filterLabel: 'Netherlands',
      value: 'NL',
      phoneCountryCode: '+31',
      phoneCountryCodeISO3166: 'NLD',
    },
    {
      label: countriesTranslation.norway,
      filterLabel: 'Norway',
      value: 'NO',
      phoneCountryCode: '+47',
      phoneCountryCodeISO3166: 'NOR',
    },
    {
      label: countriesTranslation.nepal,
      filterLabel: 'Nepal',
      value: 'NP',
      phoneCountryCode: '+977',
      phoneCountryCodeISO3166: 'NPL',
    },
    {
      label: countriesTranslation.nauru,
      filterLabel: 'Nauru',
      value: 'NR',
      phoneCountryCode: '+674',
      phoneCountryCodeISO3166: 'NRU',
    },
    {
      label: countriesTranslation.niue,
      filterLabel: 'Niue',
      value: 'NU',
      phoneCountryCode: '+683',
      phoneCountryCodeISO3166: 'NIU',
    },
    {
      label: countriesTranslation.newZealand,
      filterLabel: 'New Zealand',
      value: 'NZ',
      phoneCountryCode: '+64',
      phoneCountryCodeISO3166: 'NZL',
    },
    {
      label: countriesTranslation.oman,
      filterLabel: 'Oman',
      value: 'OM',
      phoneCountryCode: '+968',
      phoneCountryCodeISO3166: 'OMN',
    },
    {
      label: countriesTranslation.panama,
      filterLabel: 'Panama',
      value: 'PA',
      phoneCountryCode: '+507',
      phoneCountryCodeISO3166: 'PAN',
    },
    {
      label: countriesTranslation.peru,
      filterLabel: 'Peru',
      value: 'PE',
      phoneCountryCode: '+51',
      phoneCountryCodeISO3166: 'PER',
    },
    {
      label: countriesTranslation.frenchPolynesia,
      filterLabel: 'French Polynesia',
      value: 'PF',
      phoneCountryCode: '+689',
      phoneCountryCodeISO3166: 'PYF',
    },
    {
      label: countriesTranslation.papuaNewGuinea,
      filterLabel: 'Papua New Guinea',
      value: 'PG',
      phoneCountryCode: '+675',
      phoneCountryCodeISO3166: 'PNG',
    },
    {
      label: countriesTranslation.philippines,
      filterLabel: 'Philippines',
      value: 'PH',
      phoneCountryCode: '+63',
      phoneCountryCodeISO3166: 'PHL',
    },
    {
      label: countriesTranslation.pakistan,
      filterLabel: 'Pakistan',
      value: 'PK',
      phoneCountryCode: '+92',
      phoneCountryCodeISO3166: 'PAK',
    },
    {
      label: countriesTranslation.poland,
      filterLabel: 'Poland',
      value: 'PL',
      phoneCountryCode: '+48',
      phoneCountryCodeISO3166: 'POL',
    },
    {
      label: countriesTranslation.saintPierreAndMiquelon,
      filterLabel: 'Saint Pierre and Miquelon',
      value: 'PM',
      phoneCountryCode: '+508',
      phoneCountryCodeISO3166: 'SPM',
    },
    {
      label: countriesTranslation.pitcairnIslands,
      filterLabel: 'Pitcairn Islands',
      value: 'PN',
      phoneCountryCode: '+64',
      phoneCountryCodeISO3166: 'PCN',
    },
    {
      label: countriesTranslation.puertoRico,
      filterLabel: 'Puerto Rico',
      value: 'PR',
      phoneCountryCode: '+1787',
      phoneCountryCodeISO3166: 'PRI',
    },
    {
      label: countriesTranslation.palestine,
      filterLabel: 'Palestine',
      value: 'PS',
      phoneCountryCode: '+970',
      phoneCountryCodeISO3166: 'PSE',
    },
    {
      label: countriesTranslation.portugal,
      filterLabel: 'Portugal',
      value: 'PT',
      phoneCountryCode: '+351',
      phoneCountryCodeISO3166: 'PRT',
    },
    {
      label: countriesTranslation.palau,
      filterLabel: 'Palau',
      value: 'PW',
      phoneCountryCode: '+680',
      phoneCountryCodeISO3166: 'PLW',
    },
    {
      label: countriesTranslation.paraguay,
      filterLabel: 'Paraguay',
      value: 'PY',
      phoneCountryCode: '+595',
      phoneCountryCodeISO3166: 'PRY',
    },
    {
      label: countriesTranslation.qatar,
      filterLabel: 'Qatar',
      value: 'QA',
      phoneCountryCode: '+974',
      phoneCountryCodeISO3166: 'QAT',
    },
    {
      label: countriesTranslation.reunion,
      filterLabel: 'Reunion',
      value: 'RE',
      phoneCountryCode: '+262',
      phoneCountryCodeISO3166: 'REU',
    },
    {
      label: countriesTranslation.romania,
      filterLabel: 'Romania',
      value: 'RO',
      phoneCountryCode: '+40',
      phoneCountryCodeISO3166: 'ROU',
    },
    {
      label: countriesTranslation.serbia,
      filterLabel: 'Serbia',
      value: 'RS',
      phoneCountryCode: '+381',
      phoneCountryCodeISO3166: 'SRB',
    },
    {
      label: countriesTranslation.russia,
      filterLabel: 'Russia',
      value: 'RU',
      phoneCountryCode: '+7',
      phoneCountryCodeISO3166: 'RUS',
    },
    {
      label: countriesTranslation.rwanda,
      filterLabel: 'Rwanda',
      value: 'RW',
      phoneCountryCode: '+250',
      phoneCountryCodeISO3166: 'RWA',
    },
    {
      label: countriesTranslation.saudiArabia,
      filterLabel: 'Saudi Arabia',
      value: 'SA',
      phoneCountryCode: '+966',
      phoneCountryCodeISO3166: 'SAU',
    },
    {
      label: countriesTranslation.solomonIslands,
      filterLabel: 'Solomon Islands',
      value: 'SB',
      phoneCountryCode: '+677',
      phoneCountryCodeISO3166: 'SLB',
    },
    {
      label: countriesTranslation.seychelles,
      filterLabel: 'Seychelles',
      value: 'SC',
      phoneCountryCode: '+248',
      phoneCountryCodeISO3166: 'SYC',
    },
    {
      label: countriesTranslation.sudan,
      filterLabel: 'Sudan',
      value: 'SD',
      phoneCountryCode: '+249',
      phoneCountryCodeISO3166: 'SDN',
    },
    {
      label: countriesTranslation.sweden,
      filterLabel: 'Sweden',
      value: 'SE',
      phoneCountryCode: '+46',
      phoneCountryCodeISO3166: 'SWE',
    },
    {
      label: countriesTranslation.singapore,
      filterLabel: 'Singapore',
      value: 'SG',
      phoneCountryCode: '+65',
      phoneCountryCodeISO3166: 'SGP',
    },
    {
      label: countriesTranslation.saintHelena,
      filterLabel: 'Saint Helena',
      value: 'SH',
      phoneCountryCode: '+290',
      phoneCountryCodeISO3166: 'SHN',
    },
    {
      label: countriesTranslation.slovenia,
      filterLabel: 'Slovenia',
      value: 'SI',
      phoneCountryCode: '+386',
      phoneCountryCodeISO3166: 'SVN',
    },
    {
      label: countriesTranslation.svalbardAndJanMayen,
      filterLabel: 'Svalbard and Jan Mayen',
      value: 'SJ',
      phoneCountryCode: '+4779',
      phoneCountryCodeISO3166: 'SJM',
    },
    {
      label: countriesTranslation.slovakia,
      filterLabel: 'Slovakia',
      value: 'SK',
      phoneCountryCode: '+421',
      phoneCountryCodeISO3166: 'SVK',
    },
    {
      label: countriesTranslation.sierraLeone,
      filterLabel: 'Sierra Leone',
      value: 'SL',
      phoneCountryCode: '+232',
      phoneCountryCodeISO3166: 'SLE',
    },
    {
      label: countriesTranslation.sanMarino,
      filterLabel: 'San Marino',
      value: 'SM',
      phoneCountryCode: '+378',
      phoneCountryCodeISO3166: 'SMR',
    },
    {
      label: countriesTranslation.senegal,
      filterLabel: 'Senegal',
      value: 'SN',
      phoneCountryCode: '+221',
      phoneCountryCodeISO3166: 'SEN',
    },
    {
      label: countriesTranslation.somalia,
      filterLabel: 'Somalia',
      value: 'SO',
      phoneCountryCode: '+252',
      phoneCountryCodeISO3166: 'SOM',
    },
    {
      label: countriesTranslation.suriname,
      filterLabel: 'Suriname',
      value: 'SR',
      phoneCountryCode: '+597',
      phoneCountryCodeISO3166: 'SUR',
    },
    {
      label: countriesTranslation.southSudan,
      filterLabel: 'South Sudan',
      value: 'SS',
      phoneCountryCode: '+211',
      phoneCountryCodeISO3166: 'SSD',
    },
    {
      label: countriesTranslation.saoTomeAndPrincipe,
      filterLabel: 'Sao Tome and Principe',
      value: 'ST',
      phoneCountryCode: '+239',
      phoneCountryCodeISO3166: 'STP',
    },
    {
      label: countriesTranslation.elSalvador,
      filterLabel: 'El Salvador',
      value: 'SV',
      phoneCountryCode: '+503',
      phoneCountryCodeISO3166: 'SLV',
    },
    {
      label: countriesTranslation.sintMaarten,
      filterLabel: 'Sint Maarten',
      value: 'SX',
      phoneCountryCode: '+1721',
      phoneCountryCodeISO3166: 'SXM',
    },
    {
      label: countriesTranslation.syria,
      filterLabel: 'Syria',
      value: 'SY',
      phoneCountryCode: '+963',
      phoneCountryCodeISO3166: 'SYR',
    },
    {
      label: countriesTranslation.eswatini,
      filterLabel: 'Eswatini',
      value: 'SZ',
      phoneCountryCode: '+268',
      phoneCountryCodeISO3166: 'SWZ',
    },
    {
      label: countriesTranslation.turksAndCaicosIslands,
      filterLabel: 'Turks and Caicos Islands',
      value: 'TC',
      phoneCountryCode: '+1649',
      phoneCountryCodeISO3166: 'TCA',
    },
    {
      label: countriesTranslation.chad,
      filterLabel: 'Chad',
      value: 'TD',
      phoneCountryCode: '+235',
      phoneCountryCodeISO3166: 'TCD',
    },
    {
      label: countriesTranslation.frenchSouthernTerritories,
      filterLabel: 'French Southern Territories',
      value: 'TF',
      phoneCountryCode: '+262',
      phoneCountryCodeISO3166: 'ATF',
    },
    {
      label: countriesTranslation.togo,
      filterLabel: 'Togo',
      value: 'TG',
      phoneCountryCode: '+228',
      phoneCountryCodeISO3166: 'TGO',
    },
    {
      label: countriesTranslation.thailand,
      filterLabel: 'Thailand',
      value: 'TH',
      phoneCountryCode: '+66',
      phoneCountryCodeISO3166: 'THA',
    },
    {
      label: countriesTranslation.tajikistan,
      filterLabel: 'Tajikistan',
      value: 'TJ',
      phoneCountryCode: '+992',
      phoneCountryCodeISO3166: 'TJK',
    },
    {
      label: countriesTranslation.tokelau,
      filterLabel: 'Tokelau',
      value: 'TK',
      phoneCountryCode: '+690',
      phoneCountryCodeISO3166: 'TKL',
    },
    {
      label: countriesTranslation.eastTimor,
      filterLabel: 'East Timor',
      value: 'TL',
      phoneCountryCode: '+670',
      phoneCountryCodeISO3166: 'TLS',
    },
    {
      label: countriesTranslation.turkmenistan,
      filterLabel: 'Turkmenistan',
      value: 'TM',
      phoneCountryCode: '+993',
      phoneCountryCodeISO3166: 'TKM',
    },
    {
      label: countriesTranslation.tunisia,
      filterLabel: 'Tunisia',
      value: 'TN',
      phoneCountryCode: '+216',
      phoneCountryCodeISO3166: 'TUN',
    },
    {
      label: countriesTranslation.tonga,
      filterLabel: 'Tonga',
      value: 'TO',
      phoneCountryCode: '+676',
      phoneCountryCodeISO3166: 'TON',
    },
    {
      label: countriesTranslation.turkey,
      filterLabel: 'Turkey',
      value: 'TR',
      phoneCountryCode: '+90',
      phoneCountryCodeISO3166: 'TUR',
    },
    {
      label: countriesTranslation.trinidadAndTobago,
      filterLabel: 'Trinidad and Tobago',
      value: 'TT',
      phoneCountryCode: '+1868',
      phoneCountryCodeISO3166: 'TTO',
    },
    {
      label: countriesTranslation.tuvalu,
      filterLabel: 'Tuvalu',
      value: 'TV',
      phoneCountryCode: '+688',
      phoneCountryCodeISO3166: 'TUV',
    },
    {
      label: countriesTranslation.taiwan,
      filterLabel: 'Taiwan',
      value: 'TW',
      phoneCountryCode: '+886',
      phoneCountryCodeISO3166: 'TWN',
    },
    {
      label: countriesTranslation.tanzania,
      filterLabel: 'Tanzania',
      value: 'TZ',
      phoneCountryCode: '+255',
      phoneCountryCodeISO3166: 'TZA',
    },
    {
      label: countriesTranslation.ukraine,
      filterLabel: 'Ukraine',
      value: 'UA',
      phoneCountryCode: '+380',
      phoneCountryCodeISO3166: 'UKR',
    },
    {
      label: countriesTranslation.uganda,
      filterLabel: 'Uganda',
      value: 'UG',
      phoneCountryCode: '+256',
      phoneCountryCodeISO3166: 'UGA',
    },
    {
      label: countriesTranslation.USMinorOutlyingIslands,
      filterLabel: 'U.S. Minor Outlying Islands',
      value: 'UM',
      phoneCountryCode: '+1',
      phoneCountryCodeISO3166: 'UMI',
    },
    {
      label: countriesTranslation.unitedStates,
      filterLabel: 'United States',
      value: 'US',
      phoneCountryCode: '+1',
      phoneCountryCodeISO3166: 'USA',
    },
    {
      label: countriesTranslation.uruguay,
      filterLabel: 'Uruguay',
      value: 'UY',
      phoneCountryCode: '+598',
      phoneCountryCodeISO3166: 'URY',
    },
    {
      label: countriesTranslation.uzbekistan,
      filterLabel: 'Uzbekistan',
      value: 'UZ',
      phoneCountryCode: '+998',
      phoneCountryCodeISO3166: 'UZB',
    },
    {
      label: countriesTranslation.vaticanCity,
      filterLabel: 'Vatican City',
      value: 'VA',
      phoneCountryCode: '+379',
      phoneCountryCodeISO3166: 'VAT',
    },
    {
      label: countriesTranslation.saintVincentAndTheGrenadines,
      filterLabel: 'Saint Vincent and the Grenadines',
      value: 'VC',
      phoneCountryCode: '+1784',
      phoneCountryCodeISO3166: 'VCT',
    },
    {
      label: countriesTranslation.venezuela,
      filterLabel: 'Venezuela',
      value: 'VE',
      phoneCountryCode: '+58',
      phoneCountryCodeISO3166: 'VEN',
    },
    {
      label: countriesTranslation.britishVirginIslands,
      filterLabel: 'British Virgin Islands',
      value: 'VG',
      phoneCountryCode: '+1284',
      phoneCountryCodeISO3166: 'VGB',
    },
    {
      label: countriesTranslation.USVirginIslands,
      filterLabel: 'U.S. Virgin Islands',
      value: 'VI',
      phoneCountryCode: '+1340',
      phoneCountryCodeISO3166: 'VIR',
    },
    {
      label: countriesTranslation.vietnam,
      filterLabel: 'Vietnam',
      value: 'VN',
      phoneCountryCode: '+84',
      phoneCountryCodeISO3166: 'VNM',
    },
    {
      label: countriesTranslation.vanuatu,
      filterLabel: 'Vanuatu',
      value: 'VU',
      phoneCountryCode: '+678',
      phoneCountryCodeISO3166: 'VUT',
    },
    {
      label: countriesTranslation.wallisAndFutuna,
      filterLabel: 'Wallis and Futuna',
      value: 'WF',
      phoneCountryCode: '+681',
      phoneCountryCodeISO3166: 'WLF',
    },
    {
      label: countriesTranslation.samoa,
      filterLabel: 'Samoa',
      value: 'WS',
      phoneCountryCode: '+685',
      phoneCountryCodeISO3166: 'WSM',
    },
    {
      label: countriesTranslation.kosovo,
      filterLabel: 'Kosovo',
      value: 'XK',
      phoneCountryCode: '+377',
      phoneCountryCodeISO3166: 'UNK',
    },
    {
      label: countriesTranslation.yemen,
      filterLabel: 'Yemen',
      value: 'YE',
      phoneCountryCode: '+967',
      phoneCountryCodeISO3166: 'YEM',
    },
    {
      label: countriesTranslation.mayotte,
      filterLabel: 'Mayotte',
      value: 'YT',
      phoneCountryCode: '+262',
      phoneCountryCodeISO3166: 'MYT',
    },
    {
      label: countriesTranslation.southAfrica,
      filterLabel: 'South Africa',
      value: 'ZA',
      phoneCountryCode: '+27',
      phoneCountryCodeISO3166: 'ZAF',
    },
    {
      label: countriesTranslation.zambia,
      filterLabel: 'Zambia',
      value: 'ZM',
      phoneCountryCode: '+260',
      phoneCountryCodeISO3166: 'ZMB',
    },
    {
      label: countriesTranslation.zimbabwe,
      filterLabel: 'Zimbabwe',
      value: 'ZW',
      phoneCountryCode: '+263',
      phoneCountryCodeISO3166: 'ZWE',
    },
  ];

  return {
    countries: countriesToPreselect,
  };
};

export default useCountries;
