import { Fields } from './types';

export const defaultFieldsValues: Fields = {
  email: {
    value: '',
  },
  hidden: {
    value: '',
  },
  rememberMe: {
    value: false,
  },
  password: {
    value: '',
  },
};
