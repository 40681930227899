import { LoadSpinner } from '@/components/forms';
import React, { FC, useEffect, useState } from 'react';

import { SSO } from '@/components';
import {
  BottomButtons,
  DontHaveAnAccount,
  Form,
  MainHeadings,
  NeedHelp,
} from '@/components/forms';
import useTranslations from '@/hooks/useTranslations';
import { user as userStore } from '@/store';

import { useLogin } from '@/hooks';
import useUser from '@/hooks/useUser';
import { encodeURI, getAndReplaceUrlQuery, setLocalStorage } from '@/utils';

import ConfirmationMessage from '@/components/SSO/ConfirmationMessage';
import { DEBITOS_MARKETPLACE } from '@/constants';
import DoYouHaveAnDebitosAccount from './DoYouHaveAnDebitosAccount';
import styles from './index.css';
import type { LoginProps } from './types';
import zodLogin from './zod';

const LoginForm: FC<LoginProps> = ({ csrf, lang, error }) => {
  const [isProviderConnected, setIsProviderConnected] = useState(false);
  const [canContinueToMyCP, setCanContinueToMyCP] = useState(false);
  const [isSSOLoading, setIsSSOLoading] = useState(false);

  const { user } = useUser();
  const {
    components: {
      forms: {
        debitosIdOrEmail,
        emailAddress,
        forgotPassword,
        rememberID: rememberIDLabel,
        next,
        password,
        previous,
        login: loginTranslation,
        check2FA,
        validationFailed2FA,
        code2FA,
        verify2FA,
        back,
        cancel,
        invalid2FA,
        sso,
      },
    },
    pages: {
      login: { heading },
      sso: { manage, doYouHaveAnDebitosAccount, completeStatement },
    },
  } = useTranslations(lang);
  const { login2FACodeResolver } = zodLogin(lang);

  const {
    submitEmail,
    handleInput,
    handleCheckbox,
    submitPassword,
    handlePreviousStep,
    setErrors,
    currentStep,
    fields,
    errors,
    isLoading,
    login,
    loginStore,
  } = useLogin({
    lang,
  });

  const onSubmitToLogin = () => {
    toggleConnectSSO();
  };

  const resetLoginPage = () => {
    userStore.resetLogin();
  };

  const handleToMyCP = (canContinue: boolean) => {
    setCanContinueToMyCP(canContinue);
  };

  const handleSpinner = (isLoading: boolean) => {
    setIsSSOLoading(isLoading);
  };

  const onSubmit2FACode = async (data: { code: string }) => {
    loginStore.setCodeErrors([]);
    loginStore.setIsLoading2FA(true);
    const verified = await userStore.verify2FACode(data.code);
    if (verified?.token === null) {
      loginStore.setIsLoading2FA(false);
      loginStore.setCodeErrors([invalid2FA]);
      return;
    }
    return (window.location.href = `${DEBITOS_MARKETPLACE}/Signin/token/${verified.token}`);
  };

  const toggleConnectSSO = () => {
    loginStore.toggleSSO('connect');
  };

  const getURLQueries = () => {
    const queryStrings = getAndReplaceUrlQuery();

    queryStrings.forEach((query) => {
      if (query.key === 'error' && query.value === '2fa') {
        setErrors([validationFailed2FA]);
      }
      if (query.key === 'error' && query.value === 'invalid-sso-confirmation') {
        setErrors([sso.invalidSsoConfirmation]);
      }
      if (query.key === 'redirect') {
        setLocalStorage(query.key, encodeURI(query.value));
      }
      if (query.key === 'action' && query.value === 'connected-provider') {
        setIsProviderConnected(true);
      }
    });
  };

  useEffect(() => {
    getURLQueries();
  }, [error]);

  useEffect(() => {
    getURLQueries();
  }, []);

  useEffect(() => {
    loginStore.setValue({
      sso: 'manage',
    });
  }, []);

  return (
    <>
      {isSSOLoading ? (
        <div className={styles.loadingContainer}>
          <div className={styles.spinner}>
            <LoadSpinner color="primary" props={{ size: 36 }} />
          </div>
        </div>
      ) : (
        <>
          {isProviderConnected ? (
            <ConfirmationMessage lang={lang} />
          ) : (
            <div className={styles.formContainer}>
              {login.sso === null && (
                <MainHeadings
                  title={
                    login.doYouHaveAnDebitosAccount
                      ? doYouHaveAnDebitosAccount
                      : heading
                  }
                  subtitle={
                    <DontHaveAnAccount lang={lang} isDisabled={isLoading} />
                  }
                />
              )}
              {login.sso === 'connect' && (
                <MainHeadings
                  title={heading}
                  subtitle={
                    <DontHaveAnAccount lang={lang} isDisabled={isLoading} />
                  }
                />
              )}
              {login.sso === 'manage' && (
                <MainHeadings
                  title={manage.heading}
                  subtitle={manage.subheading}
                />
              )}

              {login.doYouHaveAnDebitosAccount && (
                <DoYouHaveAnDebitosAccount lang={lang} />
              )}
              <div className={styles.loginRegister}>
                <Form onSubmit={onSubmitToLogin}>
                  <div className={styles.ssoGroup}>
                    <SSO
                      hasHeading={true}
                      lang={lang}
                      handleToMyCP={handleToMyCP}
                      handleSpinner={handleSpinner}
                    />
                    <NeedHelp lang={lang} />
                    {canContinueToMyCP ? (
                      <BottomButtons
                        leftButtonIcon="back"
                        leftButtonLabel={back}
                        leftButtonOnClick={() =>
                          (window.location.href =
                            DEBITOS_MARKETPLACE + '/my-account')
                        }
                      />
                    ) : (
                      <a
                        className={styles.cancelButton}
                        href={DEBITOS_MARKETPLACE + '/my-account'}
                      >
                        {cancel.toUpperCase()}
                      </a>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default LoginForm;
