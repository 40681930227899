import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import useTranslations from '@/hooks/useTranslations';
import { Lang } from '@/i18n/types';

const loginZod = (lang: Lang) => {
  const {
    components: {
      forms: { invalidEmail, required },
    },
  } = useTranslations(lang);

  const LostPassword = z.object({
    email: z
      .string({ required_error: required })
      .trim()
      .email({ message: invalidEmail }),
  });

  const emailResolver = zodResolver(LostPassword);

  return {
    emailResolver,
  };
};

export default loginZod;
