import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';

import { useOutsideClick, useTranslations } from '@/hooks';
import styles from './index.css';
import { CountryCodeDropdownProps, Item, ListItemButton } from './types';

const COUNTRY_CODE_PLACEHOLDER = '+49';

const CountryCodeDropdown: FC<CountryCodeDropdownProps> = ({
  customStyle,
  onChange,
  items,
  selected,
  lang,
  isDisabled,
}) => {
  const [isTyping, setIsTyping] = useState(false);
  const [isListOpened, setIsListOpened] = useState(false);
  const [searchableItems, setSearchableItems] = useState<Item[]>(items);
  const [typedItem, setTypedItem] = useState<string | undefined>('');
  const [listNavigationIndex, setListNavigationIndex] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [hasArrowOpened, setHasArrowOpened] = useState(false);

  const {
    components: {
      forms: {
        countries: { notFound },
      },
    },
  } = useTranslations(lang);

  const toggleList = () => {
    setIsListOpened((prev) => !prev);
  };

  const closeList = () => {
    setIsListOpened(false);
  };

  const stopTyping = () => {
    setIsTyping(false);
  };

  const forceCloseList = () => {
    setIsListOpened(false);
    setIsTyping(false);
  };

  const handleSelectFromList = (
    e: MouseEvent<HTMLButtonElement>,
    selectedItem: Item,
  ) => {
    e.preventDefault();
    onChange('phoneCountryCode', { value: selectedItem.phoneCountryCode });
    stopTyping();
    toggleList();
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsTyping(true);
    const value = e.currentTarget.value;

    setTypedItem(value);

    const aux = items;
    const filtered = aux.filter((item) => {
      const removedSoftHyphen = item.label
        .split('')
        .filter((char) => char.charCodeAt(0) !== 173)
        .join('');
      const itemString = `${removedSoftHyphen.toLowerCase()} ${item.phoneCountryCode}`;
      return itemString.includes(value.toLowerCase());
    });

    setSearchableItems(filtered);
  };

  const handleOnClickInput = () => {
    toggleList();
  };

  const listRef = useOutsideClick(toggleList);

  const navigateList = (keyPressed: string, event: any) => {
    if (keyPressed === 'ArrowUp') {
      setListNavigationIndex((prev) => {
        if (prev === 0) {
          return prev;
        } else {
          return prev - 1;
        }
      });
    } else if (keyPressed === 'ArrowDown') {
      setListNavigationIndex((prev) => {
        if (prev < searchableItems.length - 1) {
          return prev + 1;
        } else {
          return prev;
        }
      });
    } else if (keyPressed === 'Enter') {
      event.target.click();
    }
  };

  useEffect(() => {
    if (listRef.current !== null && window !== undefined) {
      const current = listRef.current as HTMLUListElement;
      const childNodes = current.childNodes;
      const selectedNode = childNodes[listNavigationIndex];
      const selectedButton = selectedNode.childNodes[0] as ListItemButton;

      current.scrollIntoView({
        behavior: 'smooth',
      });
      selectedButton.focus();
    }
  }, [listRef, listNavigationIndex]);

  const handleInputKeyPress = (event: any) => {
    const keyPressed = event.code;

    if (keyPressed === 'Escape') {
      setIsTyping(false);
      closeList();
    } else if (keyPressed === 'ArrowUp') {
      toggleList();
    } else if (keyPressed === 'ArrowDown') {
      if (listRef.current !== undefined && isListOpened) {
        const listUl = listRef.current as HTMLUListElement;
        const firstListLi = listUl.childNodes[
          listNavigationIndex
        ] as HTMLLIElement;
        const listFirstButton = firstListLi.childNodes[0] as HTMLButtonElement;

        listFirstButton.focus();
        return;
      }
      toggleList();
    } else {
      setIsTyping(true);
    }
  };

  useEffect(() => {
    setTypedItem(selected.value);
  }, [selected]);

  const handleListKeyPress = (event: any) => {
    event.preventDefault();
    const keyPressed = event.code;

    navigateList(keyPressed, event);

    if (keyPressed === 'Escape') {
      setIsTyping(false);
      closeList();
    } else if (keyPressed === 'Tab') {
      forceCloseList();
    } else if (keyPressed === 'Enter') {
      forceCloseList();
    } else {
      setIsTyping(true);
    }
  };

  const handleBlurList = () => {
    setIsTyping(false);
  };

  const canListOpen = isListOpened || isTyping;
  const disabledStyle = isDisabled ? styles.disabled : '';

  return (
    <div className={`${customStyle} ${styles.container}`}>
      <input
        type="text"
        name="selectedCountry"
        id="selectedCountry"
        className={`${styles.input} ${disabledStyle}`}
        value={typedItem}
        onChange={handleOnChange}
        onClick={handleOnClickInput}
        autoComplete="off"
        placeholder={COUNTRY_CODE_PLACEHOLDER}
        onBlur={stopTyping}
        onKeyDown={handleInputKeyPress}
        disabled={isDisabled}
        tabIndex={1}
      />
      {canListOpen && (
        <ul
          className={`${styles.list} ${
            searchableItems.length === 0 ? styles.emptyList : ''
          }`}
          ref={listRef}
          onBlur={handleBlurList}
          onKeyDown={handleListKeyPress}
        >
          {searchableItems.length === 0 && (
            <p className={styles.notFound}>{notFound}</p>
          )}
          {searchableItems.map((item, index) => {
            const isLastItem = searchableItems.length - 1 === index;
            const isSelected = index === listNavigationIndex;
            return (
              <li
                className={`${styles.listItem} ${
                  selected.value === item.phoneCountryCode
                    ? styles.selectedListItem
                    : ''
                }`}
                key={index}
              >
                <button
                  type="button"
                  onClick={(e) => handleSelectFromList(e, item)}
                  className={`${styles.listItemButton} ${!isLastItem ? styles.listItemButtonAfter : ''} ${isSelected ? styles.listItemButtonSelected : ''}`}
                >{`${item.label} ${item.phoneCountryCode}`}</button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default CountryCodeDropdown;
