import React, { FC } from 'react';

import styles from './index.css';
import type { SeparatorProps } from './types';

const Separator: FC<SeparatorProps> = ({ children, isHidden }) => {
  const hiddenClass = isHidden ? 'hidden' : '';
  return (
    <div className={`${styles.sepContainer} ${styles[hiddenClass]}`}>
      <div className={styles.sep} />
      {children}
    </div>
  );
};

export default Separator;
