import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import {
  Google,
  GoogleDisabled,
  LinkedIn,
  LinkedInDisabled,
  Office365,
  Office365Disabled,
} from '@/assets';
import { Separator } from '@/components/forms';
import useTranslations from '@/hooks/useTranslations';
import { TrashCan } from '@carbon/icons-react';

import { AUTH_ENDPOINT } from '@/constants';
import { useSwipe } from '@/hooks';
import { fetch } from '@/services';
import { getCookie } from '@/utils';
import axios from 'axios';
import styles from './index.css';
import type { CardProps, SSOProps } from './types';

const Card: FC<CardProps> = ({
  isManageable,
  linkStyle,
  icon,
  statement,
  href,
  provider,
  onDisconnectSSOProvider,
  disabled,
}) => {
  const liRef = useRef<null | HTMLLIElement>(null);
  const { onTouchEnd, onTouchMove, onTouchStart, direction } = useSwipe({
    minSwipeDistance: 50,
  });

  return (
    <li
      ref={liRef}
      className={`${styles.ssoLi} ${isManageable ? styles.ssoLiInteractedHover : ''} ${disabled ? styles.ssoLinkDisabled : ''}`}
    >
      <a
        href={disabled ? '#' : href}
        className={`${styles.ssoLink} ${linkStyle}`}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <p>{statement}</p>
        <img src={icon} />
      </a>
      {isManageable && (
        <button
          type="button"
          className={styles.hiddenDisconnectButton}
          onClick={() => onDisconnectSSOProvider(provider)}
        >
          <TrashCan size={28} />
        </button>
      )}
    </li>
  );
};

const SSO: FC<SSOProps> = ({
  hasHeading,
  lang,
  handleToMyCP,
  handleSpinner,
}) => {
  const {
    components: {
      forms: { sso },
    },
  } = useTranslations(lang);
  const [isOffice365Connected, setIsOffice365Connected] = useState(false);
  const [isLinkedInConnected, setIsLinkedInConnected] = useState(false);
  const [isGoogleConnected, setIsGoogleConnected] = useState(false);
  const [isManageable, setIsManageable] = useState(false);
  const [isManageableOffice365, setIsManageableOffice365] = useState(false);
  const [isManageableLinkedin, setIsManageableLinkedin] = useState(false);
  const [isManageableGoogle, setIsManageableGoogle] = useState(false);
  const [toContinue, setToContinue] = useState(false);
  const [isOffice365Available, setIsOffice365Available] = useState(false);
  const [isLinkedInAvailable, setIsLinkedInAvailable] = useState(false);
  const [isGoogleAvailable, setIsGoogleAvailable] = useState(false);

  const cookie = getCookie('access_token');

  const handleDisconnectSSOProvider = async () => {
    const response = await fetch.post(AUTH_ENDPOINT + '/sso/reset', {
      user: cookie,
    });

    if (response.statusCode === 200 || response.data.result.includes('ok')) {
      setIsOffice365Connected(true);
      setIsLinkedInConnected(false);
      setIsGoogleConnected(true);
      setIsManageable(false);
      setToContinue(false);
      setIsManageableOffice365(false);
      setIsManageableLinkedin(false);
      setIsManageableGoogle(false);
    }
  };
  const getSSOConf = useCallback(async () => {
    const response = await axios.post(AUTH_ENDPOINT + '/sso/conf', {
      user: cookie,
    });
    return response;
  }, []);

  const getAvailableSSOProvider = useCallback(async () => {
    const response = await axios.get(AUTH_ENDPOINT + '/sso/providers');
    return response;
  }, []);

  useEffect(() => {
    const getConnectedProviders = async (provider: string) => {
      if (provider.includes('google')) {
        setIsGoogleConnected(true);
      }
      if (provider.includes('microsoft')) {
        setIsOffice365Connected(true);
      }

      if (provider.includes('linkedin')) {
        setIsLinkedInConnected(true);
      }
    };

    // handleSpinner(true);
    getSSOConf().then((response) => {
      if (response.data.provider.length === 0) {
        setIsManageable(false);
        setToContinue(false);
        setIsManageableOffice365(false);
        setIsManageableLinkedin(false);
        setIsManageableGoogle(false);
        getAvailableSSOProvider().then((res) => {
          res.data.providers.forEach((prov: string) => {
            getConnectedProviders(prov);
          });
        });
      } else {
        setIsManageable(true);
        setToContinue(true);
        if (response.data.provider.includes('microsoft')) {
          setIsManageableOffice365(true);
        }
        if (response.data.provider.includes('google')) {
          setIsManageableGoogle(true);
        }
        if (response.data.provider.includes('linkedin')) {
          setIsManageableLinkedin(true);
        }
        getConnectedProviders(response.data.provider);
      }
    });
  }, []);

  useEffect(() => {
    if (toContinue) {
      handleToMyCP(true);
    }
  }, [toContinue]);

  const getMessageProvider = () => {
    if (isOffice365Connected) {
      return 'Office 365';
    }
    if (isGoogleConnected) {
      return 'Google';
    }
    if (isLinkedInConnected) {
      return 'Linkedin';
    }
  };

  useEffect(() => {
    const getAvailableSSO = async () => {
      const response = await fetch.get(AUTH_ENDPOINT + '/sso/providers');
      return response.data.providers;
    };

    getAvailableSSO().then((providers: string[]) => {
      providers.forEach((provider) => {
        if (provider.includes('google')) {
          setIsGoogleAvailable(true);
        }
        if (provider.includes('microsoft')) {
          setIsOffice365Available(true);
        }
        if (provider.includes('linkedin')) {
          setIsLinkedInAvailable(true);
        }
      });
    });
  }, []);

  return (
    <Separator>
      <div className={styles.ssoContainer}>
        {hasHeading && (
          <>
            {toContinue ? (
              <div className={styles.toContinueContainer}>
                <p>{`${sso.yourConnectionSuccessfulOne} ${getMessageProvider()} ${sso.yourConnectionSuccessfulTwo}`}</p>
                <p>{sso.yourConnectionSuccessfulThree}</p>
              </div>
            ) : (
              <h2 className={styles.heading}>{sso.heading}</h2>
            )}
          </>
        )}
        <ul className={styles.ssoList}>
          {isOffice365Available && (
            <Card
              isManageable={isManageableOffice365}
              statement={
                toContinue
                  ? sso.continueWithOffice365
                  : sso.connectWithOffice365
              }
              icon={
                isOffice365Connected ? Office365.src : Office365Disabled.src
              }
              linkStyle={styles.ssoLinkOffice365}
              provider="office360"
              onDisconnectSSOProvider={handleDisconnectSSOProvider}
              href={
                !isManageable
                  ? AUTH_ENDPOINT + '/sso/register/microsoft'
                  : AUTH_ENDPOINT + '/sso/login/microsoft'
              }
              disabled={!isOffice365Connected}
            />
          )}

          {isLinkedInAvailable && (
            <Card
              isManageable={isManageableLinkedin}
              statement={
                toContinue ? sso.continueWithLinkedin : sso.connectWithLinkedin
              }
              icon={isLinkedInConnected ? LinkedIn.src : LinkedInDisabled.src}
              linkStyle={styles.ssoLinkLinkedin}
              provider="linkedin"
              onDisconnectSSOProvider={handleDisconnectSSOProvider}
              href={
                !isManageable
                  ? AUTH_ENDPOINT + '/sso/register/linkedin'
                  : AUTH_ENDPOINT + '/sso/login/linkedin'
              }
              disabled={!isLinkedInConnected}
            />
          )}

          {isGoogleAvailable && (
            <Card
              isManageable={isManageableGoogle}
              statement={
                toContinue ? sso.continueWithGoogle : sso.connectWithGoogle
              }
              icon={isGoogleConnected ? Google.src : GoogleDisabled.src}
              linkStyle={styles.ssoLinkGoogle}
              provider="google"
              onDisconnectSSOProvider={handleDisconnectSSOProvider}
              href={
                !isManageable
                  ? AUTH_ENDPOINT + '/sso/register/google'
                  : AUTH_ENDPOINT + '/sso/login/google'
              }
              disabled={!isGoogleConnected}
            />
          )}
        </ul>
      </div>
    </Separator>
  );
};

export default SSO;
