import React, { FC, useState } from 'react';

import {
  BottomButtons,
  ErrorMessages,
  Form,
  Group,
  Input,
  MainHeadings,
  NeedHelp,
} from '@/components/forms';
import useTranslations from '@/hooks/useTranslations';
import { user } from '@/store';
import { getBasePathname } from '@/utils';

import styles from './index.css';
import { AccountRecoveryProps } from './types';
import zodAccountRecovery from './zod';

const AccountRecovery: FC<AccountRecoveryProps> = ({ lang, token, type }) => {
  const { pages, components } = useTranslations(lang);
  const { resetPasswordResolver } = zodAccountRecovery(lang);
  const [errors, setErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const passwordListHints = [
    pages.lostPassword.passwordHints.tenChars,
    pages.lostPassword.passwordHints.uppercaseLowercase,
    pages.lostPassword.passwordHints.oneNumber,
    pages.lostPassword.passwordHints.specialChar,
  ];

  const onSubmit = async (data: any) => {
    setErrors([]);
    setIsLoading(true);
    const response = await user.resetPasswordRequest({
      password: data.password,
      password_confirm: data.confirmPassword,
      token,
      reset: type === 'new-password' ? false : true,
    });
    if (!response) {
      setIsLoading(false);
      setSuccess(false);
      setErrors(['Unable to change password']);
      return;
    }
    setIsLoading(false);
    setSuccess(true);
  };

  const getHeading = () => {
    return type === 'new-password'
      ? pages.lostPassword.headingSetNewPassword
      : pages.lostPassword.heading;
  };

  const getPasswordStatement = () => {
    return type === 'new-password'
      ? pages.lostPassword.passwordSet
      : pages.lostPassword.passwordReseted;
  };

  return (
    <div className={styles.formContainer}>
      <MainHeadings
        title={getHeading()}
        subtitle={pages.lostPassword.subHeading}
      />
      {success ? (
        <>
          <Group margin={1.3}>
            <h3>{getPasswordStatement()}</h3>
            <div>
              <p>{pages.lostPassword.returnToLogin}</p>
            </div>
          </Group>
          <NeedHelp lang={lang} isDisabled={isLoading} />
          <BottomButtons
            rightButtonHref={getBasePathname(lang, 'login')}
            rightButtonLabel={components.forms.login}
          />
        </>
      ) : (
        <>
          <Group>
            <h2 className={styles.chooseSecurePasswordHeading}>
              {pages.lostPassword.passwordHintHeading}
            </h2>
            <ul className={styles.chooseSecurePasswordList}>
              {passwordListHints.map((hint, index) => (
                <li key={index}>{hint}</li>
              ))}
            </ul>
          </Group>
          <Form onSubmit={onSubmit} resolver={resetPasswordResolver}>
            <Input
              name="password"
              id="password"
              type="password"
              required
              label={components.forms.enterNewPassword}
              data-testid="lost-password-form-email-input"
              placeholder={components.forms.password}
              hasSeparator={false}
            />

            <Input
              name="confirmPassword"
              id="confirmPassword"
              type="password"
              required
              label={components.forms.confirmNewPassword}
              data-testid="lost-password-form-email-input"
              placeholder={components.forms.confirmPassword}
              hasSeparator={false}
              margin={1.3}
            />

            <NeedHelp lang={lang} isDisabled={isLoading} />

            <BottomButtons
              submitLabel={components.forms.resetPassword}
              isLoading={isLoading}
            />

            <ErrorMessages errorMessages={errors} />
          </Form>
        </>
      )}
    </div>
  );
};

export default AccountRecovery;
