import React, { FC } from 'react';

import { Button } from '@/components/forms';

import styles from './index.css';
import type { BottomButtonsProps } from './types';

const BottomButtons: FC<BottomButtonsProps> = ({
  leftButtonIcon,
  leftButtonLabel,
  leftButtonOnClick,
  rightButtonOnClick,
  leftButtonHref,
  rightButtonHref,
  rightButtonLabel,
  rightButtonIcon = true,
  submitLabel,
  position = 'fixed',
  isLoading,
  rightButtonTabOrder,
  width = 'full',
  leftDataTestId,
  rightDataTestId,
}) => {
  const widthClass = width === 'full' ? styles.width : '';
  const style = {
    width: '100%',
    fontSize: '1rem',
    fontWeight: 700,
    maxInlineSize: 'unset',
    paddingInlineEnd:
      'calc(var(--cds-layout-density-padding-inline-local) * 0 + 1rem - .0625rem)',
  };

  const positionClass =
    position === 'relative' ? styles.relative : styles.fixed;

  return (
    <div className={`${styles.bottomButtons} ${positionClass} ${widthClass}`}>
      {leftButtonLabel ? (
        <>
          {leftButtonHref ? (
            <>
              <Button
                href={leftButtonHref}
                label={leftButtonLabel}
                icon={leftButtonIcon === 'back' ? 'leftArrow' : undefined}
                kind="secondary"
                style={style}
                isFullWidth
                disabled={isLoading}
                tabOrder={-1}
                dataTestId={leftDataTestId}
              />
            </>
          ) : (
            <>
              <Button
                label={leftButtonLabel}
                icon={leftButtonIcon === 'back' ? 'leftArrow' : undefined}
                kind="secondary"
                onClick={leftButtonOnClick}
                style={style}
                isFullWidth
                disabled={isLoading}
                tabOrder={-1}
                dataTestId={leftDataTestId}
              />
            </>
          )}
        </>
      ) : (
        <div className={styles.hiddenButton} />
      )}
      {submitLabel && (
        <Button
          type="submit"
          label={submitLabel}
          icon={rightButtonIcon ? 'rightArrow' : undefined}
          style={style}
          isFullWidth
          isLoading={isLoading}
          disabled={isLoading}
          tabOrder={rightButtonTabOrder}
          dataTestId={rightDataTestId}
          onClick={rightButtonOnClick}
        />
      )}
      {rightButtonHref && (
        <Button
          label={rightButtonLabel || ''}
          href={rightButtonHref}
          icon={rightButtonIcon ? 'rightArrow' : undefined}
          style={style}
          isFullWidth
          disabled={isLoading}
          tabOrder={rightButtonTabOrder}
          dataTestId={rightDataTestId}
          onClick={rightButtonOnClick}
        />
      )}
    </div>
  );
};

export default BottomButtons;
