import { NODE_RED_AUTHORIZATION_TOKEN, NODE_RED_ENDPOINT } from '@/constants';

import { fetch } from '@/services';
import { Payload } from './types';

class Register {
  async submitRegistration(payload: Payload) {
    const noderedPayload = {
      mailID: 'user-reg-start',
      to: [
        {
          email: payload.email,
        },
      ],
      data: {
        ...payload,
      },
    };
    const response = await fetch.post(
      NODE_RED_ENDPOINT + '/send-email/',
      {
        ...noderedPayload,
      },
      {
        access_token: NODE_RED_AUTHORIZATION_TOKEN,
      },
    );

    return response;
  }
}

export default new Register();
