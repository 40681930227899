import React, { FC, useState } from 'react';

import {
  BottomButtons,
  ErrorMessages,
  Form,
  Group,
  Input,
  MainHeadings,
  NeedHelp,
} from '@/components/forms';
import useTranstions from '@/hooks/useTranslations';
import { user } from '@/store';
import { getBasePathname } from '@/utils';

import styles from './index.css';
import { LostPasswordProps } from './types';
import zodLostPassword from './zod';

const TIMEOUT_WHEN_ERROR = 5000;

const LostPassword: FC<LostPasswordProps> = ({ lang }) => {
  const { pages, components } = useTranstions(lang);
  const [errors, setErrors] = useState<string[]>([]);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hints = [
    pages.lostPassword.emailHints.checkSpam,
    pages.lostPassword.emailHints.noTypos,
  ];
  const { emailResolver } = zodLostPassword(lang);

  const onSubmit = async ({ email }: any) => {
    setErrors([]);
    setIsLoading(true);

    const token = await user.resetPassword({ email });

    if (token) {
      setSuccess(true);
      setIsLoading(false);
    } else {
      setErrors([components.forms.invalidCredentials]);
      setTimeout(() => {
        setIsLoading(false);
      }, TIMEOUT_WHEN_ERROR);
    }
  };

  return (
    <div className={styles.formContainer}>
      <MainHeadings
        title={pages.lostPassword.heading}
        subtitle={pages.lostPassword.subHeading}
        dataTestId="lost-password-page-heading"
      />

      {success ? (
        <>
          <Group>
            <h2 className={styles.getBackHeading}>
              {pages.lostPassword.getBackAccountHeading}
            </h2>
            <div className={styles.invalidTokenStatementContainer}>
              <p data-testid="recovery-link-sent">
                {pages.lostPassword.resetLinkSent}
              </p>
              <p>{pages.lostPassword.checkInbox}</p>
            </div>
            <div className={styles.listContainer}>
              <p>{pages.lostPassword.inCaseEmail}</p>
              <ul className={styles.list}>
                {hints.map((hint, index) => (
                  <li key={index}>{hint}</li>
                ))}
              </ul>
            </div>
          </Group>
          <NeedHelp lang={lang} />
          <BottomButtons
            leftButtonLabel={components.forms.previous}
            leftButtonIcon="back"
          />
          <BottomButtons
            leftButtonLabel={components.forms.previous}
            leftButtonIcon="back"
            leftButtonHref={getBasePathname(lang, 'login')}
          />
        </>
      ) : (
        <>
          <Group>
            <h2 className={styles.getBackHeading}>
              {pages.lostPassword.getBackAccountHeading}
            </h2>
            <p className={styles.getBackStatement}>
              {pages.lostPassword.getBackAccountStatement}{' '}
              <strong>{pages.lostPassword.getBackAccountCode}</strong>
            </p>
          </Group>
          <Form onSubmit={onSubmit} resolver={emailResolver}>
            <Input
              name="email"
              id="email"
              type="email"
              required
              label={components.forms.debitosIdOrEmail}
              data-testid="lost-password-form-email-input"
              placeholder={components.forms.emailAddress}
              margin={1.5}
            />

            <NeedHelp lang={lang} isDisabled={isLoading} />

            <BottomButtons
              leftButtonLabel={components.forms.previous}
              leftButtonIcon="back"
              leftButtonHref={getBasePathname(lang, 'login')}
              submitLabel={components.forms.recoverAccount}
              isLoading={isLoading}
              rightDataTestId="login-form-password-recover-button"
            />

            <ErrorMessages
              errorMessages={errors}
              dataTestId="forgot-password-email-does-not-exists-error"
            />
          </Form>
        </>
      )}
    </div>
  );
};

export default LostPassword;
