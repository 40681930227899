import { FormEvent, useEffect, useRef, useState } from 'react';

import LanguagesList from '@/components/LanguageSelector/data';
import { useCountries, useTranslations } from '@/hooks';
import { Lang } from '@/i18n/types';
import { registration as registrationStore } from '@/store';
import {
  getAndReplaceUrlQuery,
  getCookie,
  getPreferredLanguage,
  getUserCountry,
  invalidateCookie,
  setCookieMinutes,
} from '@/utils';

import { AUTH_ENDPOINT } from '@/constants';
import { register } from '@/services';
import axios from 'axios';
import { defaults } from './defaults';
import {
  Company,
  CompanyParam,
  ConfirmEmail,
  ConfirmEmailParam,
  Country,
  CountryParam,
  DataPrivacyTreatment,
  DataPrivacyTreatmentParam,
  Email,
  EmailParam,
  Errors,
  FirstName,
  FirstNameParm,
  Language,
  LanguageParam,
  LastName,
  LastNameParam,
  PhoneCountryCode,
  PhoneCountryCodeParam,
  PhoneNumber,
  PhoneNumberParam,
  Sex,
  SexOption,
  Steps,
  TermsAndConditions,
  TermsAndConditionsParam,
  UseRegistrationProps,
} from './types';
import zodRegistration from './zod';

const useNewRegistration = ({ lang, isMobile }: UseRegistrationProps) => {
  const {
    components: {
      forms: {
        female,
        male,
        companyStep,
        personalStep,
        emailStep,
        searchAndConfirm,
        required,
        timeout: timeoutTranslation,
      },
    },
    pages: {
      register: { unableToCompleteRegistration },
    },
  } = useTranslations(lang);
  const { countries } = useCountries(lang);
  const languagesOptions = LanguagesList;
  const sexOptions: SexOption[] = [
    {
      value: 'female',
      label: female,
    },
    {
      value: 'male',
      label: male,
    },
  ];
  const {
    RegisterDesktopCompanyStep,
    RegisterDesktopEmailStep,
    RegisterDesktopPersonalStep,
    RegisterMobileStep,
  } = zodRegistration(lang);

  const inputRef = useRef<HTMLElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [desktopCurrentStep, setDesktopCurrentStep] = useState(
    isMobile ? 3 : 0,
  );
  const [desktopSteps, setDesktopSteps] = useState<Steps>([
    {
      label: companyStep,
    },
    {
      label: personalStep,
    },
    {
      label: emailStep,
    },
  ]);
  const [errors, setErrors] = useState<Errors>([]);

  const [company, setCompany] = useState<Company>({
    value: {
      city: '',
      houseNumber: '',
      name: '',
      street: '',
      supplierId: '',
      vatNumber: '',
      zipCode: '',
    },
  });
  const [country, setCountry] = useState<Country>();
  const [language, setLanguage] = useState<Language>();

  const [sex, setSex] = useState<Sex>();
  const [firstName, setFirstName] = useState<FirstName>({ ...defaults });
  const [lastName, setLastName] = useState<LastName>({ ...defaults });
  const [phoneCountryCode, setPhoneCountryCode] = useState<PhoneCountryCode>({
    ...defaults,
  });
  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>({ ...defaults });

  const [email, setEmail] = useState<Email>({ ...defaults });
  const [confirmEmail, setConfirmEmail] = useState<ConfirmEmail>({
    ...defaults,
  });
  const [termsAndConditions, setTermsAndConditions] =
    useState<TermsAndConditions>();
  const [dataPrivacyTreatment, setDataPrivacyTreatment] =
    useState<DataPrivacyTreatment>();

  const toggleIsLoading = () => {
    setIsLoading((prev) => !prev);
  };

  const handleCompany = (param: CompanyParam) => {
    setCompany((prev) => {
      const localCompany = prev;

      const localInvalidText = param.invalidText ?? localCompany?.invalidText;
      const localSelected = param.selected ?? localCompany?.selected;
      const localValueName = param.value?.name ?? localCompany?.value.name;
      const localValueCity = param.value?.city ?? localCompany?.value.city;
      const localValueHouseNumber =
        param.value?.houseNumber ?? localCompany?.value.houseNumber;
      const localValueStreet =
        param.value?.street ?? localCompany?.value.street;
      const localValueSupplierId =
        param.value?.supplierId ?? localCompany?.value.supplierId;
      const localValueVatNumber =
        param.value?.vatNumber ?? localCompany?.value.vatNumber;
      const localValueZipCode =
        param.value?.zipCode ?? localCompany?.value.zipCode;

      return {
        invalidText: localInvalidText,
        selected: localSelected,
        value: {
          name: localValueName,
          city: localValueCity,
          houseNumber: localValueHouseNumber,
          street: localValueStreet,
          supplierId: localValueSupplierId,
          vatNumber: localValueVatNumber,
          zipCode: localValueZipCode,
        },
      };
    });
  };

  const validateCompany = () => {
    return typeof company?.invalidText === 'undefined' ? false : true;
  };

  const handleCountry = (param: CountryParam) => {
    setCountry((prev) => {
      const localCountry = prev;

      const localInvalidText = param.invalidText ?? localCountry?.invalidText;
      const localValueLabel = param.value.label ?? localCountry?.value.label;
      const localValueValue = param.value.value ?? localCountry?.value.value;

      return {
        invalidText: localInvalidText,
        value: {
          label: localValueLabel,
          value: localValueValue,
        },
      };
    });
  };

  const validateCountry = () => {
    return typeof country?.invalidText === 'undefined' ? false : true;
  };

  const resetCompanyOnCountrySelection = () => {
    setCompany((prev) => {
      return {
        invalidText: searchAndConfirm,
        selected: false,
        value: {
          city: prev.value.city,
          houseNumber: prev.value.houseNumber,
          name: prev.value.name,
          street: prev.value.street,
          supplierId: prev.value.supplierId,
          vatNumber: prev.value.vatNumber,
          zipCode: prev.value.zipCode,
        },
      };
    });
  };

  const getDefaultCountry = () => getUserCountry();

  const getFilteredCountry = (country: string) =>
    countries.filter((countryItem) => {
      return country === countryItem.label;
    })[0];

  const getFilteredCountryLabel = (country: string) =>
    countries.filter((countryItem) => {
      return country === countryItem.filterLabel;
    })[0];

  const preselectCountryLanguageCountryPhoneCode = (lang: string) => {
    const userCountry = getDefaultCountry();
    const filteredCountry = getFilteredCountryLabel(userCountry);

    const selectedLanguage = languagesOptions.filter(
      (languageItem) => languageItem.value === lang,
    )[0];

    if (filteredCountry) {
      setCountry({
        value: {
          value: filteredCountry.value,
          label: filteredCountry.label,
        },
      });
      if (selectedLanguage) {
        setLanguage({
          value: {
            label: selectedLanguage.label,
            value: selectedLanguage.value,
          },
        });
      }
      setPhoneCountryCode({
        value: filteredCountry.phoneCountryCode,
      });
    }
  };

  const handleLanguage = (param: LanguageParam) => {
    setLanguage((prev) => {
      const localCountry = prev;

      const localInvalidText = param.invalidText ?? localCountry?.invalidText;
      const localValueLabel = param.value.label ?? localCountry?.value.label;
      const localValueValue = param.value.value ?? localCountry?.value.value;

      return {
        invalidText: localInvalidText,
        value: {
          label: localValueLabel,
          value: localValueValue,
        },
      };
    });
  };

  const validateLanguage = () => {
    return typeof language?.invalidText === 'undefined' ? false : true;
  };

  const handleSex = (value: string) => {
    setSex((prev) => {
      return {
        invalidText: prev?.invalidText,
        value,
      };
    });
  };

  const handleSexRadio = (_key: string, value: string) => {
    handleSex(value);
  };

  const validateSex = () => {
    return typeof sex?.invalidText === 'undefined' ? false : true;
  };

  const handleFirstName = (param: FirstNameParm) => {
    setFirstName((prev) => {
      const localCountry = prev;

      const localInvalidText = param.invalidText ?? localCountry?.invalidText;
      const localValue = param.value ?? localCountry?.value;

      return {
        invalidText: localInvalidText,
        value: localValue,
      };
    });
  };

  const validateFirstName = () => {
    return typeof firstName?.invalidText === 'undefined' ? false : true;
  };

  const handleFirstNameInput = (
    e: any,
    _fieldKey: string,
    errorMessage?: string,
  ) => {
    const prevValue = firstName?.value ?? '';

    handleFirstName({
      value:
        typeof e?.currentTarget?.value !== 'undefined'
          ? e?.currentTarget?.value
          : prevValue,
      invalidText: errorMessage,
    });
  };

  const handleLastName = (param: LastNameParam) => {
    setLastName((prev) => {
      const localCountry = prev;

      const localInvalidText = param.invalidText ?? localCountry?.invalidText;
      const localValue = param.value ?? localCountry?.value;

      return {
        invalidText: localInvalidText,
        value: localValue,
      };
    });
  };

  const validateLastName = () => {
    return typeof lastName?.invalidText === 'undefined' ? false : true;
  };

  const handleLastNameInput = (
    e: any,
    _fieldKey: string,
    errorMessage?: string,
  ) => {
    const prevValue = firstName?.value ?? '';

    handleLastName({
      value:
        typeof e?.currentTarget?.value !== 'undefined'
          ? e?.currentTarget?.value
          : prevValue,
      invalidText: errorMessage,
    });
  };

  const handlePhoneCountryCode = (param: PhoneCountryCodeParam) => {
    setPhoneCountryCode((prev) => {
      const localCountry = prev;

      const localInvalidText = param.invalidText ?? localCountry?.invalidText;
      const localValue = param.value ?? localCountry?.value;

      return {
        invalidText: localInvalidText,
        value: localValue,
      };
    });
  };

  const validatePhoneCountryCode = () => {
    return typeof phoneCountryCode?.invalidText === 'undefined' ? false : true;
  };

  const handlePhoneCountryCodeInput = (
    fieldKey: string,
    value: PhoneCountryCodeParam,
  ) => {
    handlePhoneCountryCode(value);
  };

  const handlePhoneNumber = (param: PhoneNumberParam) => {
    setPhoneNumber((prev) => {
      const localCountry = prev;

      const localInvalidText = param.invalidText ?? localCountry?.invalidText;
      const localValue = param.value ?? localCountry?.value;

      return {
        invalidText: localInvalidText,
        value: localValue,
      };
    });
  };

  const validatePhoneNumber = () => {
    return typeof phoneNumber?.invalidText === 'undefined' ? false : true;
  };

  const handlePhoneNumberInput = (
    e: any,
    _fieldKey: string,
    errorMessage?: string,
  ) => {
    const prevValue = firstName?.value ?? '';

    handlePhoneNumber({
      value:
        typeof e?.currentTarget?.value !== 'undefined'
          ? e?.currentTarget?.value
          : prevValue,
      invalidText: errorMessage,
    });
  };

  const handleEmail = (param: EmailParam) => {
    setEmail((prev) => {
      const localCountry = prev;

      const localInvalidText = param.invalidText ?? localCountry?.invalidText;
      const localValue = param.value ?? localCountry?.value;

      return {
        invalidText: localInvalidText,
        value: localValue,
      };
    });
  };

  const handleEmailInput = (
    e: any,
    _fieldKey: string,
    errorMessage?: string,
  ) => {
    const prevValue = firstName?.value ?? '';

    handleEmail({
      value:
        typeof e?.currentTarget?.value !== 'undefined'
          ? e?.currentTarget?.value
          : prevValue,
      invalidText: errorMessage,
    });
  };

  const validateEmail = () => {
    return typeof email?.invalidText === 'undefined' ? false : true;
  };

  const handleConfirmEmail = (param: ConfirmEmailParam) => {
    setConfirmEmail((prev) => {
      const localCountry = prev;

      const localInvalidText = param.invalidText ?? localCountry?.invalidText;
      const localValue = param.value ?? localCountry?.value;

      return {
        invalidText: localInvalidText,
        value: localValue,
      };
    });
  };

  const handleConfirmEmaillInput = (
    e: any,
    _fieldKey: string,
    errorMessage?: string,
  ) => {
    const prevValue = firstName?.value ?? '';

    handleConfirmEmail({
      value:
        typeof e?.currentTarget?.value !== 'undefined'
          ? e?.currentTarget?.value
          : prevValue,
      invalidText: errorMessage,
    });
  };

  const validateConfirmEmail = () => {
    return typeof confirmEmail?.invalidText === 'undefined' ? false : true;
  };

  const handleTermsAndConditions = (param: TermsAndConditionsParam) => {
    setTermsAndConditions((prev) => {
      const localCountry = prev;

      const localInvalidText = param.invalidText ?? localCountry?.invalidText;
      const localValue = param.value ?? localCountry?.value;

      return {
        invalidText: localInvalidText,
        value: localValue,
      };
    });
  };

  const handleTermsAndConditionsCheckbox = () => {
    handleTermsAndConditions({
      value: !termsAndConditions?.value,
    });
  };

  const validateDataTermsAndConditions = () => {
    return typeof termsAndConditions?.invalidText === 'undefined'
      ? false
      : true;
  };

  const handleDataPrivacyTreatment = (param: DataPrivacyTreatmentParam) => {
    setDataPrivacyTreatment((prev) => {
      const localCountry = prev;

      const localInvalidText = param.invalidText ?? localCountry?.invalidText;
      const localValue = param.value ?? localCountry?.value;

      return {
        invalidText: localInvalidText,
        value: localValue,
      };
    });
  };

  const validateDataPrivacyTreatment = () => {
    return typeof dataPrivacyTreatment?.invalidText === 'undefined'
      ? false
      : true;
  };

  const handleDataPrivacyTreatmentCheckbox = () => {
    handleDataPrivacyTreatment({
      value: !dataPrivacyTreatment?.value,
    });
  };

  const fetchSkyMinderWithTimeout = (
    iso3166: {
      label: string;
      filterLabel: string;
      value: string;
      phoneCountryCode: string;
      phoneCountryCodeISO3166: string;
    }[],
    companyName: string,
    timeout: number,
  ) => {
    return Promise.race([
      axios({
        method: 'POST',
        url: AUTH_ENDPOINT + '/company/',
        data: {
          companyName: companyName,
          countryCode: iso3166[0].phoneCountryCodeISO3166,
          timeout: timeout,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }),
    ]);
  };

  const getCompanyList = async (companyName: string): Promise<any> => {
    const iso3166 = countries.filter((countryItem) => {
      return countryItem.value === country?.value.value;
    });

    try {
      const response = (await fetchSkyMinderWithTimeout(
        iso3166,
        companyName,
        50000,
      )) as any;

      if (response.status !== 200) {
        return [];
      }

      if (
        response.data.message !== undefined &&
        response.data.message.length > 0
      ) {
        return response.data.message;
      } else {
        return [];
      }
    } catch (error: any) {
      if (error.message === timeoutTranslation) {
        return [];
      }

      return [];
    }
  };

  const handleNextStep = () => {
    registrationStore.nextStep();
    setDesktopCurrentStep((prev) => prev + 1);
  };

  const handlePreviousStep = () => {
    registrationStore.previousStep();
    setDesktopCurrentStep((prev) => prev - 1);
  };

  const clearProgressIndicatorErrors = () => {
    setDesktopSteps((prev) => {
      return prev.map((value) => ({
        label: value.label,
        invalid: false,
      }));
    });
  };

  const parseErrors = () => {
    switch (desktopCurrentStep) {
      case 0: {
        return RegisterDesktopCompanyStep.safeParse({
          company: { ...company },
          country: { ...country },
          language: { ...language },
        });
      }
      case 1: {
        return RegisterDesktopPersonalStep.safeParse({
          sex: { ...sex },
          firstName: { ...firstName },
          lastName: { ...lastName },
          phoneCountryCode: { ...phoneCountryCode },
          phoneNumber: { ...phoneNumber },
        });
      }
      case 2: {
        return RegisterDesktopEmailStep.safeParse({
          email: { ...email },
          confirmEmail: { ...confirmEmail },
          termsAndConditions: { ...termsAndConditions },
          dataPrivacyTreatment: { ...dataPrivacyTreatment },
        });
      }
      default: {
        return RegisterMobileStep.safeParse({
          company: { ...company },
          country: { ...country },
          language: { ...language },
          sex: { ...sex },
          firstName: { ...firstName },
          lastName: { ...lastName },
          phoneCountryCode: { ...phoneCountryCode },
          phoneNumber: { ...phoneNumber },
          email: { ...email },
          confirmEmail: { ...confirmEmail },
          termsAndConditions: { ...termsAndConditions },
          dataPrivacyTreatment: { ...dataPrivacyTreatment },
        });
      }
    }
  };

  const triggerErrors = (errorObject: { [key: string]: any }) => {
    Object.entries(errorObject).forEach((obj) => {
      const key = obj[0];
      const value = obj[1];

      switch (key) {
        case 'company': {
          // @ts-ignore
          setCompany((prev) => {
            return {
              value: prev?.value,
              invalidText: value.invalidText,
            };
          });
          break;
        }
        case 'country': {
          // @ts-ignore
          setCountry((prev) => {
            return {
              value: prev?.value,
              invalidText: value.invalidText,
            };
          });
          break;
        }
        case 'language': {
          // @ts-ignore
          setLanguage((prev) => {
            return {
              value: prev?.value,
              invalidText: value.invalidText,
            };
          });
          break;
        }
        case 'sex': {
          // @ts-ignore
          setSex((prev) => {
            return {
              value: prev?.value,
              invalidText: value.invalidText,
            };
          });
          break;
        }
        case 'firstName': {
          setFirstName((prev) => {
            return {
              value: prev?.value,
              invalidText: value.invalidText,
            };
          });
          break;
        }
        case 'lastName': {
          setLastName((prev) => {
            return {
              value: prev?.value,
              invalidText: value.invalidText,
            };
          });
          break;
        }
        case 'phoneCountryCode': {
          setPhoneCountryCode((prev) => {
            return {
              value: prev?.value,
              invalidText: value.invalidText,
            };
          });
          break;
        }
        case 'phoneNumber': {
          setPhoneNumber((prev) => {
            return {
              value: prev?.value,
              invalidText: value.invalidText,
            };
          });
          break;
        }
        case 'email': {
          setEmail((prev) => {
            return {
              value: prev?.value,
              invalidText: value.invalidText,
            };
          });
          break;
        }
        case 'confirmEmail': {
          setConfirmEmail((prev) => {
            return {
              value: prev?.value,
              invalidText: value.invalidText,
            };
          });
          break;
        }
        case 'termsAndConditions': {
          setTermsAndConditions((prev) => {
            return {
              value: prev?.value,
              invalidText: value.invalidText,
            };
          });
          break;
        }
        case 'dataPrivacyTreatment': {
          setDataPrivacyTreatment((prev) => {
            return {
              value: prev?.value,
              invalidText: value.invalidText,
            };
          });
          break;
        }
      }
    });
  };

  const handleErrors = () => {
    clearProgressIndicatorErrors();

    const parsedErrors = parseErrors();

    if (parsedErrors.success) return true;

    const errorsFound = parsedErrors.error.issues;
    const errorObjects = {} as any;

    errorsFound.forEach((error) => {
      const path = error.path[0];
      const message = error.message;
      const invalidTextObject = {
        invalidText: message,
      };

      errorObjects[path] = invalidTextObject;
    });

    triggerErrors(errorObjects);

    return false;
  };

  const handleDesktopStep = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errorHandling = handleErrors();

    if (errorHandling) {
      if (desktopCurrentStep < 2) {
        handleNextStep();
      }
      return true;
    }

    return false;
  };

  const getRegistrationRequestBody = () => {
    const referral = getCookie('referral');

    return {
      country: country?.value.value,
      company: company.value,
      language: language?.value.value,
      sex: sex?.value,
      firstName: firstName?.value,
      lastName: lastName?.value,
      // @ts-ignore
      phone: phoneCountryCode?.value + phoneNumber?.value,
      email: email?.value,
      confirmEmail: confirmEmail?.value,
      termsAndConditions: termsAndConditions?.value,
      dataPrivacyTreatment: dataPrivacyTreatment?.value,
      ...(referral && { referral }),
    };
  };

  const handleDesktopCompanyForm = (e: FormEvent<HTMLFormElement>) => {
    const stepHandled = handleDesktopStep(e);
  };

  const handleDesktopPersonalForm = (e: FormEvent<HTMLFormElement>) => {
    const stepHandled = handleDesktopStep(e);
  };

  const handleDesktopEmailForm = async (e: FormEvent<HTMLFormElement>) => {
    const stepHandled = handleDesktopStep(e);

    if (stepHandled) {
      setIsLoading(true);

      try {
        const requestBody = getRegistrationRequestBody();
        const response = await register.submitRegistration(requestBody);

        invalidateCookie('referral');

        if (response.data.ok) {
          registrationStore.setValue({
            hasRegistrationSucceeded: true,
            email: requestBody.email,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setErrors([unableToCompleteRegistration]);
        }
      } catch (error) {
        setIsLoading(false);
        setErrors([unableToCompleteRegistration]);
      }
    }
  };

  const handleMobileForm = async (e: FormEvent<HTMLFormElement>) => {
    const stepHandled = handleDesktopStep(e);

    if (stepHandled) {
      setIsLoading(true);

      try {
        const requestBody = getRegistrationRequestBody();
        const response = await register.submitRegistration(requestBody);

        invalidateCookie('referral');

        if (response.data.ok) {
          registrationStore.setValue({
            hasRegistrationSucceeded: true,
            email: requestBody.email,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setErrors([unableToCompleteRegistration]);
        }
      } catch (error) {
        setIsLoading(false);
        setErrors([unableToCompleteRegistration]);
      }
    }
  };

  useEffect(() => {
    const preferredLanguage = getPreferredLanguage();
    preselectCountryLanguageCountryPhoneCode(preferredLanguage as Lang);
  }, []);

  useEffect(() => {
    if (country?.value.label && country?.value.label.length > 0) {
      const filteredCountry = getFilteredCountry(country.value.label);
      handlePhoneCountryCode({
        value: filteredCountry.phoneCountryCode,
      });
    }
  }, [country?.value?.value]);

  useEffect(() => {
    const queryStrings = getAndReplaceUrlQuery();

    queryStrings.forEach((query) => {
      if (query.key === 'referral') {
        setCookieMinutes(query.key, encodeURI(query.value), 60);
      }
    });
  }, []);

  useEffect(() => {
    if (company?.value.name) {
      // @ts-ignore
      setCompany((prev) => {
        return {
          invalidText: undefined,
          value: prev?.value,
          selected: prev.selected,
        };
      });
    }
  }, [company?.value.name]);

  useEffect(() => {
    if (sex?.value) {
      // @ts-ignore
      setSex((prev) => {
        return {
          invalidText: undefined,
          value: prev?.value,
        };
      });
    }
  }, [sex?.value]);

  useEffect(() => {
    if (firstName?.value && firstName.value.length > 0) {
      setFirstName((prev) => {
        return {
          invalidText: undefined,
          value: prev?.value,
        };
      });
    }
  }, [firstName?.value]);

  useEffect(() => {
    if (lastName?.value && lastName.value.length > 0) {
      setLastName((prev) => {
        return {
          invalidText: undefined,
          value: prev?.value,
        };
      });
    }
  }, [lastName?.value]);

  useEffect(() => {
    if (phoneNumber?.value && phoneNumber.value.length > 0) {
      setPhoneNumber((prev) => {
        return {
          invalidText: undefined,
          value: prev?.value,
        };
      });
    }
  }, [phoneNumber?.value]);

  useEffect(() => {
    if (email?.value && email.value.length > 0) {
      setEmail((prev) => {
        return {
          invalidText: undefined,
          value: prev?.value,
        };
      });
    }
  }, [email?.value]);

  useEffect(() => {
    if (confirmEmail?.value && confirmEmail.value.length > 0) {
      setConfirmEmail((prev) => {
        return {
          invalidText: undefined,
          value: prev?.value,
        };
      });
    }
  }, [confirmEmail?.value]);

  useEffect(() => {
    if (termsAndConditions?.value) {
      setTermsAndConditions((prev) => {
        return {
          invalidText: undefined,
          value: prev?.value,
        };
      });
    }
  }, [termsAndConditions?.value]);

  useEffect(() => {
    if (dataPrivacyTreatment?.value) {
      setDataPrivacyTreatment((prev) => {
        return {
          invalidText: undefined,
          value: prev?.value,
        };
      });
    }
  }, [dataPrivacyTreatment?.value]);

  return {
    inputRef,
    errors,
    isLoading,
    company,
    country,
    language,
    sex,
    firstName,
    lastName,
    phoneCountryCode,
    phoneNumber,
    email,
    confirmEmail,
    termsAndConditions,
    dataPrivacyTreatment,
    desktopCurrentStep,
    desktopSteps,
    countries,
    languagesOptions,
    sexOptions,
    toggleIsLoading,
    handleCompany,
    validateCompany,
    resetCompanyOnCountrySelection,
    preselectCountryLanguageCountryPhoneCode,
    handleCountry,
    validateCountry,
    handleLanguage,
    validateLanguage,
    handleSex,
    validateSex,
    handleSexRadio,
    handleFirstName,
    validateFirstName,
    handleFirstNameInput,
    handleLastName,
    validateLastName,
    handleLastNameInput,
    handlePhoneCountryCode,
    validatePhoneCountryCode,
    handlePhoneCountryCodeInput,
    handlePhoneNumber,
    validatePhoneNumber,
    handlePhoneNumberInput,
    handleEmail,
    handleEmailInput,
    validateEmail,
    handleConfirmEmail,
    handleConfirmEmaillInput,
    validateConfirmEmail,
    handleTermsAndConditions,
    validateDataTermsAndConditions,
    handleTermsAndConditionsCheckbox,
    handleDataPrivacyTreatment,
    validateDataPrivacyTreatment,
    handleDataPrivacyTreatmentCheckbox,
    handleDesktopCompanyForm,
    handleDesktopPersonalForm,
    handleDesktopEmailForm,
    getCompanyList,
    handleNextStep,
    handlePreviousStep,
    handleMobileForm,
  };
};

export default useNewRegistration;
