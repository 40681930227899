import React, { FC } from 'react';

import { InputSideButtonProps } from './types';

const InputSideButton: FC<InputSideButtonProps> = ({
  href,
  label,
  isDisabled = false,
  dataTestId 
}) => {
  return <a {...(isDisabled ? {} : { href })} data-testid = {dataTestId}>{label}</a>;
};

export default InputSideButton;
