import { InProgress } from '@carbon/icons-react';
import React, { FC } from 'react';

import styles from './index.css';
import { LoadSpinnerProps } from './types';

const LoadSpinner: FC<LoadSpinnerProps> = ({ props, color }) => {
  const getColor = () => {
    switch (color) {
      case 'primary': {
        return styles.primaryColor;
      }
      case 'white': {
        return styles.whiteColor;
      }
      default: {
        return styles.whiteColor;
      }
    }
  };
  return (
    <div className={`${styles.spinner} ${getColor()}`}>
      <InProgress {...props} />
    </div>
  );
};

export default LoadSpinner;
