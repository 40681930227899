import { TextInput } from '@carbon/react';
import '@carbon/react/scss/components/text-input/_index.scss';
import React, { FC, useEffect } from 'react';

import { Separator } from '@/components/forms';

import styles from './index.css';
import type { InputProps } from './types';

const InputComponent: FC<InputProps> = ({
  register,
  name,
  id,
  required,
  disabled,
  label,
  type,
  sideButton,
  invalid,
  margin,
  padding,
  value,
  tabOrder,
  isDisabled = false,
  handleProgressIndicator,
  ...rest
}) => {
  const autoComplete = type === 'password' ? 'new-password' : undefined;
  const hiddenClass = id === 'hidden' ? styles.hidden : '';
  const dynamicType = id === 'hidden' ? 'password' : type;
  const registered =
    typeof register !== 'undefined'
      ? {
          ...register(name, {
            required,
            disabled,
          }),
        }
      : null;

  useEffect(() => {
    if (handleProgressIndicator && typeof invalid !== 'undefined') {
      handleProgressIndicator(invalid);
    }
  }, [invalid]);

  return (
    <div
      className={`${styles.inputContainer} ${hiddenClass}`}
      style={{
        ...(margin ? { marginBottom: `${margin}rem` } : {}),
      }}
    >
      <div className={styles.labelLinkContainer}>{sideButton}</div>
      <TextInput
        className={styles.input}
        {...registered}
        id={id}
        {...(typeof autoComplete !== 'undefined'
          ? { autoComplete: autoComplete }
          : {})}
        type={dynamicType}
        labelText={label}
        size="lg"
        value={value}
        invalid={invalid}
        disabled={isDisabled}
        tabIndex={1}
        style={{
          ...(tabOrder ? { order: tabOrder } : {}),
          ...(padding ? { paddingLeft: `${padding}rem` } : {}),
        }}
        {...rest}
      />
    </div>
  );
};

const Input: FC<InputProps> = ({ hasSeparator = true, ...rest }) => {
  return (
    <>
      {hasSeparator ? (
        <Separator>
          <InputComponent {...rest} />
        </Separator>
      ) : (
        <InputComponent {...rest} />
      )}
    </>
  );
};

export default Input;
