import { useState } from 'react';

import { UseSwipeProps } from './type';

const useSwipe = ({
  minSwipeDistance,
  onEndEvent,
  onMoveEvent,
  onStartEvent,
}: UseSwipeProps) => {
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const [direction, setDirection] = useState<'left' | 'right' | null>(null);

  const onTouchStart = (e: any) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
    if (onStartEvent) {
      onStartEvent();
    }
  };

  const onTouchMove = (e: any) => {
    setTouchEnd(e.targetTouches[0].clientX);
    if (onMoveEvent) {
      onMoveEvent();
    }
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      setDirection(isLeftSwipe ? 'left' : 'right');
    }
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    direction,
  };
};

export default useSwipe;
