import React, { FC } from 'react';

import { useNewRegistration, useTranslations } from '@/hooks';

import {
  BottomButtons,
  ErrorMessages,
  NeedHelp,
  Separator,
} from '@/components/forms';
import {
  Checkbox,
  CompanyNameInput,
  CountryCodeDropdown,
  Input,
  RadioButton,
  SearchableDropdown,
} from '@/components/forms/newRegistration';
import {
  getBasePathname,
  getExternalLinks,
  sortCountriesAlphabetically,
} from '@/utils';
import styles from './index.css';
import { RegisterMobileProps } from './types';

const RegisterMobile: FC<RegisterMobileProps> = ({ lang, csrf }) => {
  const {
    components: {
      forms: {
        emailAddress,
        emailAddressRegistration,
        confirmEmailAddressVerification,
        confirmEmailAddressVerificationRegistration,
        dataPrivacyCheckbox,
        termsAndConditionsCheckbox,
        country: countryTranslation,
        companyName,
        firstName: firstNameTranslation,
        lastName: lastNameTranslation,
        phoneNumber: phoneNumberTranslation,
        register,
        previous,
        next,
        cancel,
        sex: sexTranslation,
        language: languageTranslation,
      },
    },
  } = useTranslations(lang);

  const {
    inputRef,
    errors,
    isLoading,
    desktopSteps,
    desktopCurrentStep,
    company,
    countries,
    country,
    languagesOptions,
    language,
    sexOptions,
    sex,
    firstName,
    lastName,
    phoneCountryCode,
    phoneNumber,
    email,
    confirmEmail,
    dataPrivacyTreatment,
    termsAndConditions,
    handleDesktopCompanyForm,
    getCompanyList,
    handleCompany,
    validateCompany,
    validateCountry,
    resetCompanyOnCountrySelection,
    handleCountry,
    handleLanguage,
    validateLanguage,
    validateSex,
    handleSexRadio,
    validateFirstName,
    validateLastName,
    handleFirstNameInput,
    handleDesktopPersonalForm,
    handlePreviousStep,
    handlePhoneCountryCodeInput,
    handlePhoneNumberInput,
    handleLastNameInput,
    validatePhoneNumber,
    validateEmail,
    handleEmailInput,
    validateConfirmEmail,
    handleConfirmEmaillInput,
    handleTermsAndConditionsCheckbox,
    handleDataPrivacyTreatmentCheckbox,
    handleMobileForm,
  } = useNewRegistration({ lang, isMobile: true });
  return (
    <form onSubmit={handleMobileForm}>
      <input type="hidden" id="_token" name="_token" value={csrf} />
      <div className={styles.multiInputFormMobile}>
        <SearchableDropdown
          label={countryTranslation}
          options={sortCountriesAlphabetically(countries)}
          id="countries"
          handler={handleCountry}
          value={country}
          invalid={validateCountry()}
          invalidText={country?.invalidText}
          placeholder={countryTranslation}
          isDisabled={isLoading}
          lang={lang}
          resetFields={resetCompanyOnCountrySelection}
          tabOrder={1}
          margin={1.3}
          tabIndex={1}
        />

        <CompanyNameInput
          label={companyName}
          lang={lang}
          APICall={getCompanyList}
          value={company}
          id="companyName"
          onSelection={handleCompany}
          placeholder={companyName}
          invalid={validateCompany()}
          invalidText={company?.invalidText}
          inputRef={inputRef}
          isDisabled={isLoading}
          tabIndex={1}
        />

        <SearchableDropdown
          label={languageTranslation}
          options={languagesOptions}
          id="language"
          handler={handleLanguage}
          value={language}
          invalid={validateLanguage()}
          invalidText={language?.invalidText}
          margin={1.3}
          isDisabled={isLoading}
          tabOrder={3}
          lang={lang}
          placeholder={languageTranslation}
          tabIndex={1}
          dataTestId={'registration-page-language-dropdown'}
        />

        <RadioButton
          groupLabel={sexTranslation}
          handler={handleSexRadio}
          name="sex"
          options={sexOptions}
          invalid={validateSex()}
          invalidText={sex?.invalidText}
          fieldKey="sex"
          hasSeparator={false}
          value={sex?.value}
          isDisabled={isLoading}
          orientation="horizontal"
        />

        <Input
          name="firstName"
          id="firstName"
          type="text"
          required
          invalid={validateFirstName()}
          invalidText={firstName?.invalidText}
          label={firstNameTranslation}
          data-testid="register-form-first-name-input"
          value={firstName?.value}
          placeholder={firstNameTranslation}
          onChange={(e: any) => handleFirstNameInput(e, 'firstName')}
          hasSeparator={false}
          isDisabled={isLoading}
          tabOrder={5}
          tabIndex={1}
        />

        <Input
          name="lastName"
          id="lastName"
          type="text"
          required
          invalid={validateLastName()}
          invalidText={lastName?.invalidText}
          label={lastNameTranslation}
          data-testid="register-form-last-name"
          value={lastName?.value}
          placeholder={lastNameTranslation}
          onChange={(e: any) => handleLastNameInput(e, 'lastName')}
          hasSeparator={false}
          isDisabled={isLoading}
          tabIndex={1}
        />
        <div className={styles.countryCodeContainer}>
          <CountryCodeDropdown
            customStyle={styles.countryCode}
            items={sortCountriesAlphabetically(countries)}
            onChange={handlePhoneCountryCodeInput}
            lang={lang}
            selected={{ ...phoneCountryCode }}
            isDisabled={isLoading}
          />
          <Input
            name="phone"
            id="phone"
            type="text"
            required
            invalid={validatePhoneNumber()}
            invalidText={phoneNumber?.invalidText}
            label={phoneNumberTranslation}
            data-testid="register-form-phone-input"
            value={phoneNumber?.value}
            placeholder={phoneNumberTranslation}
            onChange={(e: any) => handlePhoneNumberInput(e, 'phoneNumber')}
            hasSeparator={false}
            margin={1.5}
            padding={3.7}
            isDisabled={isLoading}
            tabOrder={7}
          />
        </div>
        <Input
          name="email"
          id="email"
          type="text"
          required
          invalid={validateEmail()}
          invalidText={email?.invalidText}
          label={emailAddress}
          data-testid="register-form-first-name-input"
          value={email?.value}
          placeholder={emailAddressRegistration}
          onChange={(e: any) => handleEmailInput(e, 'email')}
          hasSeparator={false}
          isDisabled={isLoading}
          tabOrder={8}
        />
        <Input
          name="confirmEmail"
          id="confirmEmail"
          type="text"
          required
          invalid={validateConfirmEmail()}
          invalidText={confirmEmail?.invalidText}
          label={confirmEmailAddressVerification}
          data-testid="register-form-first-name-input"
          value={confirmEmail?.value}
          placeholder={confirmEmailAddressVerificationRegistration}
          onChange={(e: any) => handleConfirmEmaillInput(e, 'confirmEmail')}
          hasSeparator={false}
          isDisabled={isLoading}
          tabOrder={9}
        />
        <Separator>
          <div className={styles.checkboxContainer}>
            <Checkbox
              handleCheckbox={() => handleTermsAndConditionsCheckbox()}
              isChecked={termsAndConditions?.value}
              id="termsAndConditions"
              label={termsAndConditionsCheckbox}
              invalid={
                typeof termsAndConditions?.invalidText === 'undefined'
                  ? false
                  : true
              }
              invalidText={termsAndConditions?.invalidText}
              isDisabled={isLoading}
              tabOrder={24}
              url={getExternalLinks('/terms-and-conditions/', lang)}
            />
            <Checkbox
              handleCheckbox={() => handleDataPrivacyTreatmentCheckbox()}
              isChecked={dataPrivacyTreatment?.value}
              id="privacyTretment"
              label={dataPrivacyCheckbox}
              invalid={
                typeof dataPrivacyTreatment?.invalidText === 'undefined'
                  ? false
                  : true
              }
              invalidText={dataPrivacyTreatment?.invalidText}
              margin={1}
              isDisabled={isLoading}
              tabOrder={10}
              url={getExternalLinks('/data-protection/', lang)}
            />
          </div>
        </Separator>
        <BottomButtons
          submitLabel={register}
          hasSubmitIcon
          position="relative"
          leftButtonLabel={cancel}
          leftButtonIcon="back"
          leftButtonHref={getBasePathname(lang, 'login')}
          isLoading={isLoading}
          isDisabled={isLoading}
          rightButtonTabOrder={11}
        />
        <NeedHelp lang={lang} isDisabled={isLoading} />
        <ErrorMessages errorMessages={errors} />
      </div>
    </form>
  );
};

export default RegisterMobile;
