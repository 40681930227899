import React, { FC, useEffect, useRef, useState } from 'react';

import { Google, LinkedIn, Office365 } from '@/assets';
import { Separator } from '@/components/forms';
import useTranslations from '@/hooks/useTranslations';

import { AUTH_ENDPOINT } from '@/constants';
import { useSwipe } from '@/hooks';
import { fetch } from '@/services';
import styles from './index.css';
import type { CardProps, SSOProps } from './types';

const Card: FC<CardProps> = ({
  isManageable,
  linkStyle,
  icon,
  statement,
  href,
  provider,
  onDisconnectSSOProvider,
  dataTestId,
}) => {
  const liRef = useRef<null | HTMLLIElement>(null);
  const { onTouchEnd, onTouchMove, onTouchStart, direction } = useSwipe({
    minSwipeDistance: 50,
  });

  useEffect(() => {
    if (isManageable) {
      if (direction === 'left') {
        liRef.current?.classList.remove(styles.ssoLiInteractedRight);
        liRef.current?.classList.add(styles.ssoLiInteractedLeft);
      } else if (direction === 'right') {
        liRef.current?.classList.remove(styles.ssoLiInteractedLeft);
        liRef.current?.classList.add(styles.ssoLiInteractedRight);
      }
    }
  }, [direction, isManageable]);

  useEffect(() => {
    if (isManageable) {
      liRef.current?.classList.add(styles.ssoLiHover);
      setTimeout(() => {
        liRef.current?.classList.remove(styles.ssoLiHover);
      }, 1500);

      liRef.current?.addEventListener('mouseleave', () => {
        liRef.current?.classList.add(styles.ssoLiInteractedRight);
        setTimeout(() => {
          liRef.current?.classList.remove(styles.ssoLiInteractedRight);
        }, 100);
      });
    }
  }, [isManageable]);

  const openTargetBlankWindow = () => {
    window.open(
      href,
      'SSO Window',
      'left=50%,top=50%,width=600,height=800,toolbar=0,resizable=0',
    );
    return true;
  };

  return (
    <li
      ref={liRef}
      className={`${styles.ssoLi} ${isManageable ? styles.ssoLiInteractedHover : ''}`}
      data-testid={dataTestId}
    >
      <a
        href={href}
        className={`${styles.ssoLink} ${linkStyle}`}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <img src={icon} />
      </a>
    </li>
  );
};

const SSO: FC<SSOProps> = ({ hasHeading, lang, isManageable }) => {
  const {
    components: {
      forms: { sso },
    },
  } = useTranslations(lang);
  const [isOffice365Connected, setIsOffice365Connected] = useState(false);
  const [isLinkedInConnected, setIsLinkedInConnected] = useState(false);
  const [isGoogleConnected, setIsGoogleConnected] = useState(false);

  const handleDisconnectSSOProvider = async (provider: string) => {
    const response = await fetch.post(AUTH_ENDPOINT + '/auth/disconnect', {
      provider,
    });
  };

  useEffect(() => {
    const getAvailableSSO = async () => {
      const response = await fetch.get(AUTH_ENDPOINT + '/sso/providers');
      return response?.data?.providers;
    };

    if (isManageable) {
      getAvailableSSO().then((providers: string[]) => {
        if (providers !== undefined) {
          providers.forEach((provider) => {
            if (provider !== undefined) {
              if (provider.includes('google')) {
                setIsGoogleConnected(true);
              }
              if (provider.includes('microsoft')) {
                setIsOffice365Connected(true);
              }
              if (provider.includes('linkedin')) {
                setIsLinkedInConnected(true);
              }
            }
          });
        }
      });
    }
  }, [isManageable]);

  return (
    <Separator>
      <div className={styles.ssoContainer}>
        {hasHeading && <h2 className={styles.heading}>{sso.headingTwo}</h2>}
        <ul className={styles.ssoList}>
          {isOffice365Connected && (
            <Card
              isManageable={isOffice365Connected}
              statement={
                isOffice365Connected
                  ? sso.continueWithOffice365
                  : sso.connectWithOffice365
              }
              icon={Office365.src}
              linkStyle={styles.ssoLinkOffice365}
              provider="office360"
              onDisconnectSSOProvider={handleDisconnectSSOProvider}
              href={AUTH_ENDPOINT + '/sso/login/microsoft'}
              dataTestId="officeSSOBtn"
            />
          )}
          {isLinkedInConnected && (
            <Card
              isManageable={isLinkedInConnected}
              statement={
                isLinkedInConnected
                  ? sso.continueWithLinkedin
                  : sso.connectWithLinkedin
              }
              icon={LinkedIn.src}
              linkStyle={styles.ssoLinkLinkedin}
              provider="linkedin"
              onDisconnectSSOProvider={handleDisconnectSSOProvider}
              href={AUTH_ENDPOINT + '/sso/login/linkedin'}
              dataTestId="linkedinSSOBtn"
            />
          )}
          {isGoogleConnected && (
            <Card
              isManageable={isGoogleConnected}
              statement={
                isGoogleConnected
                  ? sso.continueWithGoogle
                  : sso.connectWithGoogle
              }
              icon={Google.src}
              linkStyle={styles.ssoLinkGoogle}
              provider="google"
              onDisconnectSSOProvider={handleDisconnectSSOProvider}
              href={AUTH_ENDPOINT + '/sso/login/google'}
              dataTestId="googleSSOBtn"
            />
          )}
        </ul>
      </div>
    </Separator>
  );
};

export default SSO;
