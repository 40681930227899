import React, { FC } from 'react';

import { LogoWithoutClaim } from '@/assets';
import { BulletPoint, HamburguerMenu } from '@/components';
import { MainHeadings, NeedHelp, Separator } from '@/components/forms';
import { useApp, useTranslations } from '@/hooks';

import styles from './index.css';
import { SuccessfulMessageProps } from './types';

const SuccessfulMessage: FC<SuccessfulMessageProps> = ({
  lang,
  userEmail,
  url,
}) => {
  const {
    pages: {
      register: {
        heading,
        thankYou: {
          successfulMessageOne,
          successfulMessageTwo,
          instructionFour,
          instructionOne,
          instructionThree,
          instructionTwo,
          instructionsHeading,
          pleaseVerifyEmailOne,
          pleaseVerifyEmailTwo,
        },
      },
    },
  } = useTranslations(lang);
  const { app } = useApp();

  return (
    <div className={styles.container}>
      <div className={styles.headers}>
        {app.device === 'mobile' && (
          <div className={styles.logoWithoutClaimContainer}>
            <a href="/">
              <img
                src={LogoWithoutClaim.src}
                className={styles.logoWithoutClaim}
              />
            </a>
            <HamburguerMenu lang={lang} url={url} />
          </div>
        )}
        <MainHeadings
          title={heading}
          subtitle={
            <div className={styles.multiLineSubtitle}>
              <p>{successfulMessageOne}</p>
              <p>{successfulMessageTwo}</p>
            </div>
          }
        />
      </div>

      <Separator>
        <div className={styles.lines}>
          <p>{pleaseVerifyEmailOne}</p>
          <p>
            {pleaseVerifyEmailTwo}
            <br />
            <b>{userEmail}</b>
          </p>
        </div>
      </Separator>
      <Separator>
        <div className={styles.lines}>
          <p>{instructionsHeading}</p>
          <BulletPoint
            items={[
              instructionOne,
              instructionTwo,
              instructionThree,
              instructionFour,
            ]}
          />
        </div>
      </Separator>
      <NeedHelp lang={lang} />
    </div>
  );
};

export default SuccessfulMessage;
