import { useTranslations } from '@/hooks';
import { Lang } from '@/i18n/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const zodLogin = (lang: Lang) => {
  const {
    components: {
      forms: { mustContain6Chars, passwordAtLeast10Chars, invalidEmail },
    },
  } = useTranslations(lang);
  const LoginEmailCredentials = z.object({
    email: z.string().trim().email({ message: invalidEmail }),
    hidden: z.string(),
  });

  const LoginPasswordCredentials = z.object({
    password: z.string().min(10, { message: passwordAtLeast10Chars }),
  });

  const Login2FACode = z.object({
    code: z
      .string()
      .min(6, { message: mustContain6Chars })
      .max(6, { message: mustContain6Chars }),
  });

  const DoYouHaveAnDebitosAccountEmail = z.object({
    email: z.string().trim().email({ message: invalidEmail }),
  });

  const emailResolver = zodResolver(LoginEmailCredentials);

  const passwordResolver = zodResolver(LoginPasswordCredentials);

  const login2FACodeResolver = zodResolver(Login2FACode);

  const doYouHaveAnDebitosAccountEmailResolver = zodResolver(
    DoYouHaveAnDebitosAccountEmail,
  );

  return {
    emailResolver,
    passwordResolver,
    login2FACodeResolver,
    doYouHaveAnDebitosAccountEmailResolver,
  };
};

export default zodLogin;
