import { MapStore, map } from 'nanostores';

import { AppState, AppStateSetter, Value } from './types';

class App {
  $app: MapStore<AppState> = map<AppState>({
    device: 'desktop',
  });

  private set({ device }: AppStateSetter) {
    const app = this.$app.get();

    const localDevice = device ?? app.device;

    this.$app.set({
      device: localDevice,
    });
  }

  setValue(value: Value) {
    this.set(value);
  }

  setDevice() {
    if (typeof window !== 'undefined') {
      const width = window.innerWidth;

      if (width <= 968) {
        this.setValue({ device: 'mobile' });
      } else if (width > 968 && width <= 1270) {
        this.setValue({ device: 'tablet' });
      } else if (width > 1270) {
        this.setValue({ device: 'desktop' });
      }
    }
  }
}

export default new App();
