import { RadioButton, RadioButtonGroup } from '@carbon/react';
import '@carbon/react/scss/components/radio-button/_index.scss';
import React, { FC } from 'react';

import { Separator } from '@/components/forms';

import styles from './index.css';
import { RadioButtonProps } from './types';

const Component: FC<RadioButtonProps> = ({
  groupLabel,
  name,
  id,
  fieldKey,
  orientation = 'vertical',
  options,
  invalid,
  invalidText,
  handler,
  value,
  isDisabled,
  tabOrder,
  ...rest
}) => {
  const handleRadioGroupSelection = (value: any) => {
    handler(fieldKey, value);
  };

  const invalidateInput = () => {
    if (invalidText) {
      if (invalidText.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <div className={styles.container}>
      <RadioButtonGroup
        id={id}
        legendText={groupLabel}
        name={name}
        orientation={orientation}
        onChange={handleRadioGroupSelection}
        invalid={invalidateInput()}
        invalidText={invalidText}
        disabled={isDisabled}
        style={{
          ...(tabOrder ? { order: tabOrder } : {}),
        }}
        {...rest}
      >
        {options.map((option, index) => {
          const isChecked = value === option.value;
          return (
            <RadioButton
              key={index}
              labelText={option.label}
              value={option.value}
              tabIndex={1}
              checked={isChecked}
            />
          );
        })}
      </RadioButtonGroup>
    </div>
  );
};

const RadioButtonComponent: FC<RadioButtonProps> = ({
  hasSeparator,
  ...rest
}) => {
  return (
    <>
      {hasSeparator ? (
        <Separator>
          <Component {...rest} />
        </Separator>
      ) : (
        <Component {...rest} />
      )}
    </>
  );
};

export default RadioButtonComponent;
