import { z } from 'zod';

import { Lang } from '@/i18n/types';
import useTranslations from '../useTranslations';

const loginZod = (lang: Lang) => {
  const {
    components: {
      forms: {
        errorMessages: { mustNotBeEmpty },
        passwordTooLong,
        required,
        invalidEmail,
      },
    },
  } = useTranslations(lang);

  const LoginEmailStep = z.object({
    email: z.object({
      invalidText: z.optional(z.string()),
      value: z
        .string({ required_error: required })
        .email({ message: invalidEmail })
        .min(5, { message: mustNotBeEmpty }),
    }),
    hidden: z.object({
      invalidText: z.optional(z.string()),
      value: z.string().max(0),
    }),
  });
  const LoginPasswordStep = z.object({
    password: z.object({
      invalidText: z.optional(z.string()),
      value: z
        .string({ required_error: required })
        .min(1, { message: required })
        .max(125, { message: passwordTooLong }),
    }),
  });
  const Login2FAStep = z.object({
    code: z.object({
      invalidText: z.optional(z.string()),
      value: z.string().min(6, { message: mustNotBeEmpty }),
    }),
  });

  return {
    LoginEmailStep,
    LoginPasswordStep,
    Login2FAStep,
  };
};

export default loginZod;
