import { Information } from '@carbon/icons-react';
import { Checkbox as CheckboxCarbon } from '@carbon/react';
import '@carbon/react/scss/components/checkbox/_index.scss';
import React, { FC } from 'react';

import { Separator } from '@/components/forms';

import styles from './index.css';
import type { CheckboxProps } from './types';

const Component: FC<CheckboxProps> = ({
  label,
  isChecked = false,
  handleCheckbox,
  id,
  margin,
  invalid,
  isDisabled,
  tabOrder,
  url,
  dataTestId,
  ...rest
}) => {
  const handleIsChecked = () => {
    handleCheckbox();
  };

  return (
    <div
      className={styles.container}
      style={{
        ...(margin ? { margin: `0 0 ${margin}rem 0` } : {}),
      }}
    >
      <CheckboxCarbon
        checked={isChecked}
        labelText={label}
        id={id}
        onClick={handleIsChecked}
        className={styles.checkbox}
        invalid={invalid}
        disabled={isDisabled}
        data-testid={dataTestId}
        style={{
          ...(tabOrder ? { order: tabOrder } : {}),
        }}
        {...rest}
      />
      {url && (
        <a href={url} target="_blank">
          <Information size={20} className={styles.info} />
        </a>
      )}
    </div>
  );
};

const Checkbox: FC<CheckboxProps> = ({ hasSeparator, ...rest }) => {
  return (
    <>
      {hasSeparator ? (
        <Separator>
          <Component {...rest} />
        </Separator>
      ) : (
        <Component {...rest} />
      )}
    </>
  );
};

export default Checkbox;
