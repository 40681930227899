// @ts-ignore
import { ProgressIndicator, ProgressStep } from '@carbon/react';
import '@carbon/react/scss/components/progress-indicator/_index.scss';
import React, { FC } from 'react';

import styles from './index.css';
import { ProgressIndicatorProps } from './types';

const ProgressBarIndicatorComponent: FC<ProgressIndicatorProps> = ({
  steps,
  spaceEqually = false,
  vertical = false,
  currentIndex,
}) => {
  return (
    <ProgressIndicator
      spaceEqually={spaceEqually}
      vertical={vertical}
      currentIndex={currentIndex}
      className={styles.progressBar}
    >
      {steps.map(
        (
          { label, complete, description, disabled, invalid, secondaryLabel },
          index,
        ) => (
          <ProgressStep
            key={index}
            complete={complete}
            label={label}
            description={description}
            secondaryLabel={secondaryLabel}
            disabled={disabled}
            invalid={invalid}
          />
        ),
      )}
    </ProgressIndicator>
  );
};

export default ProgressBarIndicatorComponent;
