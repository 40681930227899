import React, { FC } from 'react';

import styles from './index.css';
import { BulletPointListProps } from './types';

const BulletPoint: FC<BulletPointListProps> = ({ items }) => {
  return (
    <ul className={styles.list}>
      {items.map((item, index) => (
        <li key={index} className={styles.item}>
          {item}
        </li>
      ))}
    </ul>
  );
};

export default BulletPoint;
