import React, { FC } from 'react';

import { Separator } from '@/components/forms';

import styles from './index.css';
import type { GroupProps } from './types';

const Group: FC<GroupProps> = ({ children, margin }) => {
  return (
    <Separator>
      <div
        className={styles.group}
        style={{
          ...(margin ? { marginBottom: `${margin}rem` } : {}),
        }}
      >
        {children}
      </div>
    </Separator>
  );
};

export default Group;
