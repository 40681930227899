import { MapStore, map } from 'nanostores';

import { RegistrationState, RegistrationStateSetter, Value } from './types';

class Registration {
  $registration: MapStore<RegistrationState> = map<RegistrationState>({
    email: '',
    hasRegistrationSucceeded: false,
    currentStep: 0,
  });

  private set({
    email,
    hasRegistrationSucceeded,
    currentStep,
  }: RegistrationStateSetter) {
    const registration = this.$registration.get();

    const localEmail = email ?? registration.email;
    const localHasRegistrationSucceeded =
      hasRegistrationSucceeded ?? registration.hasRegistrationSucceeded;
    const localCurrentStep = currentStep ?? registration.currentStep;

    this.$registration.set({
      email: localEmail,
      hasRegistrationSucceeded: localHasRegistrationSucceeded,
      currentStep: localCurrentStep,
    });
  }

  setValue(value: Value) {
    this.set(value);
  }

  nextStep() {
    const currentStep = this.$registration.get().currentStep;
    this.setValue({ currentStep: currentStep + 1 });
  }

  previousStep() {
    const currentStep = this.$registration.get().currentStep;
    this.setValue({ currentStep: currentStep - 1 });
  }
}

export default new Registration();
