import React, { FC, Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import styles from './index.css';
import type { FormProps } from './types';

const Form: FC<FormProps> = ({
  defaultValues,
  onSubmit,
  children,
  resolver,
  valuesToSet,
}) => {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues, resolver, mode: 'onBlur' });

  const handleSetValue = () => {
    if (valuesToSet) {
      Object.entries(valuesToSet).forEach((obj) => {
        setValue(obj[0], obj[1]);
      });
    }
  };

  useEffect(() => {
    if (valuesToSet) {
      handleSetValue();
    }
  }, [valuesToSet]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      {Array.isArray(children)
        ? children.map((child, index) => {
            const errorMessage = errors[child.props.name]?.message as string;
            const isInvalid =
              typeof errorMessage === 'string' && errorMessage.length > 0;
            return (
              <Fragment key={index}>
                {child.props.name
                  ? React.createElement(child.type, {
                      ...{
                        ...child.props,
                        register,
                        key: child.props.name,
                        invalid: isInvalid,
                        invalidText: errorMessage,
                      },
                    })
                  : child}
              </Fragment>
            );
          })
        : children}
    </form>
  );
};

export default Form;
