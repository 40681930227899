import { MapStore, map } from 'nanostores';

import { ColorSchemeType } from '@/components/ThemeSelector/types';
import { ColorSchemeState } from './types';

class ColorScheme {
  $colorScheme: MapStore<ColorSchemeState> = map<ColorSchemeState>({
    label: 'Light',
    value: 'light',
  });

  set(color: ColorSchemeType) {
    this.$colorScheme.set({ ...color });
  }

  setColorScheme(color: ColorSchemeType) {
    this.set(color);
  }
}

export default new ColorScheme();
