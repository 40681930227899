import { InProgress } from '@carbon/icons-react';
import React, { FC } from 'react';

import styles from './index.css';
import { LoadSpinnerProps } from './types';

const LoadSpinner: FC<LoadSpinnerProps> = ({ props }) => {
  return (
    <div className={`${styles.spinner}`}>
      <InProgress {...props} />
    </div>
  );
};

export default LoadSpinner;
