import React, { FC } from 'react';

import { LogoWithoutClaim } from '@/assets';
import { HamburguerMenu } from '@/components';
import { MainHeadings } from '@/components/forms';
import { useApp, useRegistration, useTranslations } from '@/hooks';
import RegistrationBackground from '@/layouts/RegistrationLayout/RegistrationBackground';

import SuccessfulMessage from './SuccessfulMessage';
import RegisterDesktop from './desktop';
import RegisterMobile from './mobile';

import styles from './index.css';
import { RegisterProps } from './types';

const RegisterForm: FC<RegisterProps> = ({ csrf, lang, url }) => {
  const {
    pages: {
      register: { heading, subtitle },
    },
  } = useTranslations(lang);
  const { app } = useApp();
  const { registration } = useRegistration({ lang });

  return (
    <>
      <aside className={styles.aside}>
        <RegistrationBackground lang={lang} />
      </aside>
      <aside className={styles.mainContent}>
        <div
          className={`${styles.formContainer} ${
            app.device === 'mobile' ? styles.formContainerMobile : ''
          }`}
          style={{
            marginTop: app.device === 'mobile' ? '1rem' : '4rem',
          }}
        >
          {registration.hasRegistrationSucceeded ? (
            <>
              <SuccessfulMessage
                lang={lang}
                userEmail={registration.email}
                url={url}
              />
            </>
          ) : (
            <>
              <div
                className={`${styles.headers} ${
                  app.device === 'mobile' ? styles.headersMobile : ''
                }`}
              >
                {app.device === 'mobile' && (
                  <div className={styles.logoWithoutClaimContainer}>
                    <a href="/" tabIndex={-1}>
                      <img
                        src={Object(LogoWithoutClaim).src}
                        className={styles.logoWithoutClaim}
                      />
                    </a>
                    <HamburguerMenu lang={lang} url={url} />
                  </div>
                )}
                <MainHeadings
                  title={heading}
                  subtitle={subtitle}
                  dataTestId="signup-page-heading"
                />
              </div>
              <div className={styles.register}>
                {app.device === 'desktop' || app.device === 'tablet' ? (
                  <RegisterDesktop lang={lang} csrf={csrf} />
                ) : (
                  <RegisterMobile lang={lang} csrf={csrf} />
                )}
              </div>
            </>
          )}
        </div>
      </aside>
    </>
  );
};

export default RegisterForm;
