import React, { FC, Fragment } from 'react';

import styles from './index.css';
import { MultiInputFormProps } from './types';

const MultiInputForm: FC<MultiInputFormProps> = ({ children, onSubmit }) => {
  return (
    <form onSubmit={onSubmit} className={styles.form}>
      {Array.isArray(children)
        ? children.map((child, index) => (
            <Fragment key={index}>{child}</Fragment>
          ))
        : children}
    </form>
  );
};

export default MultiInputForm;
