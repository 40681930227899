import timezones from './data';

export const generateTimezonesDictionary = () => {
  let userRegion;
  let userCity;
  let userCountry;
  let userTimeZone;

  if (Intl) {
    userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzArr = userTimeZone.split('/');
    userRegion = tzArr[0];
    userCity = tzArr[tzArr.length - 1];
    userCountry = timezones[userCity];
  }
  return {
    timezone: userTimeZone,
    region: userRegion,
    city: userCity,
    country: userCountry,
  };
};

export const getUserCountry = () => generateTimezonesDictionary().country;
