import { useStore } from '@nanostores/react';

import { app as appStore } from '@/store';
import { AppStateSetter } from '@/store/app/types';

const useApp = () => {
  const app = useStore(appStore.$app);

  const setValue = (value: AppStateSetter) => {
    appStore.setValue(value);
  };

  return { app, setValue };
};

export default useApp;
