import { ZodIssueCode, z } from 'zod';

import { Lang } from '@/i18n/types';
import useTranslations from '../useTranslations';

const phoneRegex = /^\d+$/;

const zodRegistration = (lang: Lang) => {
  const {
    components: {
      forms: {
        mustNotBeEmpty,
        emailsMustMatch,
        mustBeSelected,
        required,
        atLeast4Chars,
        atMost16Chars,
        onlyNumber,
        selectionRequired,
      },
    },
  } = useTranslations(lang);

  const RegisterDesktopCompanyStep = z.object({
    companyName: z.object({
      invalidText: z.optional(z.string()),
      hasSelected: z.optional(
        z.boolean({ required_error: selectionRequired }).refine((value) => {
          return value;
        }),
      ),
      value: z
        .string({ required_error: required })
        .min(1, { message: mustNotBeEmpty }),
    }),
    country: z.object({
      invalidText: z.optional(z.string()),
      value: z
        .string({ required_error: required })
        .min(1, { message: mustNotBeEmpty }),
    }),
    language: z.object({
      invalidText: z.optional(z.string()),
      value: z
        .string({ required_error: required })
        .min(1, { message: mustNotBeEmpty }),
    }),
  });

  const RegisterDesktopPersonalStep = z.object({
    sex: z.object({
      invalidText: z.optional(z.string()),
      value: z
        .string({ required_error: required })
        .min(1, { message: mustBeSelected }),
    }),
    firstName: z.object({
      invalidText: z.optional(z.string()),
      value: z
        .string({ required_error: required })
        .min(1, { message: mustNotBeEmpty }),
    }),
    lastName: z.object({
      invalidText: z.optional(z.string()),
      value: z
        .string({ required_error: required })
        .min(1, { message: mustNotBeEmpty }),
    }),
    phoneNumber: z.object({
      invalidText: z.optional(z.string()),
      value: z
        .string({ required_error: required })
        .min(4, { message: atLeast4Chars })
        .max(16, { message: atMost16Chars })
        .trim()
        .refine((value) => phoneRegex.test(value), onlyNumber),
    }),
  });

  const RegisterDesktopEmailStep = z
    .object({
      email: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty })
          .email(),
      }),
      confirmEmail: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty })
          .email(),
      }),
      termsAndConditions: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .boolean({ required_error: mustNotBeEmpty })
          .refine((value) => value === true, {
            message: mustBeSelected,
          }),
      }),
      dataPrivacyTreatment: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .boolean({ required_error: mustNotBeEmpty })
          .refine((value) => value === true, {
            message: mustBeSelected,
          }),
      }),
    })
    .superRefine(({ email, confirmEmail }, ctx) => {
      if (email.value !== confirmEmail.value) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: emailsMustMatch,
          path: ['confirmEmail'],
        });
      }
    });

  const RegisterMobileStep = z
    .object({
      companyName: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty }),
      }),
      country: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty }),
      }),
      language: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty }),
      }),
      sex: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty }),
      }),
      firstName: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty }),
      }),
      lastName: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty }),
      }),
      phoneNumber: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(4, { message: atLeast4Chars })
          .max(16, { message: atMost16Chars })
          .trim()
          .refine((value) => phoneRegex.test(value), onlyNumber),
      }),
      email: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty }),
      }),
      confirmEmail: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .string({ required_error: required })
          .min(1, { message: mustNotBeEmpty }),
      }),
      termsAndConditions: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .boolean({ required_error: mustNotBeEmpty })
          .refine((value) => value === true, {
            message: mustBeSelected,
          }),
      }),
      dataPrivacyTreatment: z.object({
        invalidText: z.optional(z.string()),
        value: z
          .boolean({ required_error: mustNotBeEmpty })
          .refine((value) => value === true, {
            message: mustBeSelected,
          }),
      }),
    })
    .superRefine(({ email, confirmEmail }, ctx) => {
      if (email.value !== confirmEmail.value) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: emailsMustMatch,
          path: ['confirmEmail'],
        });
      }
    });

  return {
    RegisterDesktopCompanyStep,
    RegisterDesktopPersonalStep,
    RegisterDesktopEmailStep,
    RegisterMobileStep,
  };
};

export default zodRegistration;
