import React, { FC } from 'react';

import {
  HexLogin,
  LogoWithoutClaimWhite,
  RegisterBg1,
  RegisterBg2,
  RegisterBg3,
  RegisterThankYouBg,
} from '@/assets';
import { useRegistration } from '@/hooks';

import styles from './index.css';
import { RegistrationBackgroundProps } from './types';

const RegistrationBackground: FC<RegistrationBackgroundProps> = ({ lang }) => {
  const { registration } = useRegistration({ lang });

  const getBackgroundImage = () => {
    switch (registration.currentStep) {
      case 0: {
        return RegisterBg1;
      }
      case 1: {
        return RegisterBg2;
      }
      case 2: {
        return RegisterBg3;
      }
    }
  };

  return (
    <>
      {!registration.hasRegistrationSucceeded ? (
        <>
          <div
            className={styles.hex}
            style={{
              backgroundImage: `url(${Object(HexLogin).src})`,
            }}
          />
          <div className={styles.gradient} />
          <a href="/" className={styles.brand} tabIndex={-1}>
            <img src={Object(LogoWithoutClaimWhite).src} />
          </a>
        </>
      ) : (
        <>
          <div className={styles.thankYouBg} />
          <a href="/" className={styles.brand} tabIndex={-1}>
            <img src={Object(LogoWithoutClaimWhite).src} />
          </a>
        </>
      )}
      <div
        className={`${styles.bg} ${
          registration.hasRegistrationSucceeded
            ? styles.bgContainerSuccess
            : styles.bgContainer
        }`}
        style={{
          backgroundImage: `url(${
            registration.hasRegistrationSucceeded
              ? Object(RegisterThankYouBg).src
              : ''
          })`,
          ...(registration.hasRegistrationSucceeded
            ? { position: 'relative' }
            : {}),
        }}
      >
        {!registration.hasRegistrationSucceeded && (
          <img
            src={Object(getBackgroundImage()).src}
            className={styles.image}
          />
        )}
      </div>
    </>
  );
};

export default RegistrationBackground;
