import React, { FC } from 'react';

import useTranslations from '@/hooks/useTranslations';
import { getBasePathname } from '@/utils';

import styles from './index.css';
import { DontHaveAnAccountProps } from './types';

const DontHaveAnAccount: FC<DontHaveAnAccountProps> = ({
  lang,
  isDisabled = false,
  dataTestId,
}) => {
  const {
    pages: {
      login: { dontHaveAnAccount, signUp },
    },
  } = useTranslations(lang);
  return (
    <p className={styles.signupStatement}>
      {dontHaveAnAccount}{' '}
      <a
        {...(isDisabled ? {} : { href: getBasePathname(lang, 'register') })}
        className={styles.signupLink}
        data-testid={dataTestId}
      >
        {signUp}
      </a>
    </p>
  );
};

export default DontHaveAnAccount;
