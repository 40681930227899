import { Fields } from './types';

export const defaultFieldsValues: Fields = {
  companyName: {
    value: '',
  },
  confirmEmail: {
    value: '',
  },
  country: {
    value: '',
  },
  dataPrivacyTreatment: {
    value: false,
  },
  email: {
    value: '',
  },
  firstName: {
    value: '',
  },
  language: {
    value: '',
  },
  lastName: {
    value: '',
  },
  phoneCountryCode: {
    value: '',
  },
  phoneNumber: {
    value: '',
  },
  sex: {
    value: undefined,
  },
  termsAndConditions: {
    value: false,
  },
};
