import { useStore } from '@nanostores/react';

import { user as userStore } from '@/store';

const useUser = () => {
  const user = useStore(userStore.$user);

  return { user };
};

export default useUser;
