import React, { FC } from 'react';

import { NeedHelp, Separator } from '@/components/forms';
import { BulletPoint } from '@/components/list';
import { useLogin, useTranslations } from '@/hooks';
import styles from '../index.css';
import zodLogin from '../zod';
import { ConfirmEmailProps } from './types';

const ConfirmEmail: FC<ConfirmEmailProps> = ({ lang, confirmEmail }) => {
  const {
    components: {
      forms: { sso },
    },
  } = useTranslations(lang);
  const { doYouHaveAnDebitosAccountEmailResolver } = zodLogin(lang);

  const {
    handleInput,
    submitDoYouHaveAnDebitosAccountEmail,
    fields,
    isLoading,
    loginStore,
  } = useLogin({
    lang,
  });

  const onSubmitFromDoYouHaveAnDebitosAccountToLogin = () => {
    loginStore.setDoYouHaveAnDebitosAccount(false);
  };

  return (
    <>
      <Separator>
        <p className={styles.completeStatement}>
          {sso.confirmEmailVerifyEmail}
          <br />
          {sso.confirmEmail15Minutes}
          <br />
          {confirmEmail}
        </p>
      </Separator>
      <Separator>
        <p>{sso.confirmEmailIfCantGetEmailReason}</p>
        <BulletPoint
          items={[
            sso.confirmEmailIfCantGetEmailReason1,
            sso.confirmEmailIfCantGetEmailReason2,
            sso.confirmEmailIfCantGetEmailReason3,
            sso.confirmEmailIfCantGetEmailReason4,
          ]}
        />
      </Separator>
      <NeedHelp lang={lang} />
    </>
  );
};

export default ConfirmEmail;
