import React, { FC } from 'react';

import {
  BottomButtons,
  Group,
  MainHeadings,
  NeedHelp,
} from '@/components/forms';
import useTranslations from '@/hooks/useTranslations';
import { getBasePathname } from '@/utils';

import styles from './index.css';
import { InvalidTokenProps } from './types';

const InvalidToken: FC<InvalidTokenProps> = ({ lang }) => {
  const { pages } = useTranslations(lang);

  return (
    <div className={styles.formContainer}>
      <MainHeadings
        title={pages.lostPassword.heading}
        subtitle={pages.lostPassword.subHeading}
      />

      <Group>
        <h2 className={styles.invalidTokenHeading}>
          {pages.lostPassword.invalidCode}
        </h2>
        <div className={styles.invalidTokenStatementContainer}>
          <p>{pages.lostPassword.codeValidForOneHour}</p>
          <p>{pages.lostPassword.tryRequesting}</p>
        </div>
      </Group>

      <NeedHelp lang={lang} />
      <BottomButtons
        rightButtonLabel={pages.lostPassword.tryAgain}
        rightButtonHref={getBasePathname(lang, 'lost-password')}
        hasSubmitIcon
      />
    </div>
  );
};

export default InvalidToken;
