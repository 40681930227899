import { IS_DEVELOPMENT } from '@/constants';
import { Lang } from '@/i18n/types';
import { ToQueryParams } from './types';

export const toCapitalize = (string: string) => {
  const initialLetter = string.substring(0, 1).toUpperCase();
  const remaining = string.substring(1, string.length);

  return initialLetter.concat(remaining);
};

export const toCapitalizeAll = (string: string) => {
  const allCapitalized = string
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase());

  return allCapitalized;
};

export const toQuery = (params: ToQueryParams) => {
  let query = '?';
  const length = Object.entries(params).length;

  Object.entries(params).forEach((entry, index) => {
    if (entry[1] === null) return;
    query += `${entry[0]}=${entry[1]}`;

    if (index !== length - 1) {
      query += '&';
      return;
    }
  });

  return query;
};

export const fromURLToQueryObject = (url: string) => {
  const split = url.split('?');

  if (split.length <= 1) {
    return null;
  }

  const query = split[1];
  const queryObject = query.split('&').map((q) => {
    // const splitKeyValue = q.split('=');
    // return {: }
    return q;
  });

  return queryObject;
};

export const getBasePathname = (lang: Lang, pathname: string) => {
  if (IS_DEVELOPMENT) {
    return ['', lang, pathname].join('/');
  }
  return ['', 'd2', lang, pathname].join('/');
};

export const replaceBySameQuery = (lang: Lang, pathname: string) => {
  if (typeof window === 'undefined') {
    return null;
  }

  const objectQuery = fromURLToQueryObject(window.location.href);

  return objectQuery;
};

export const encodeURI = (string: string) => encodeURIComponent(string);

export const decodeURI = (string: string) => decodeURIComponent(string);

export const sortCountriesAlphabetically = (array: any[]) => {
  const objects = array;
  objects.sort(function (a, b) {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  return objects;
};

export const toCammelCase = (string: string) => {
  const camelCasedString = string.replace(/\s(\w)/g, function (match, letter) {
    return letter.toUpperCase();
  });

  return camelCasedString.charAt(0).toLowerCase() + camelCasedString.slice(1);
};
