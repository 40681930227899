import React, { FC } from 'react';

import {
  BottomButtons,
  ErrorMessages,
  NeedHelp,
  ProgressIndicator,
  RadioButton,
  Separator,
} from '@/components/forms';
import {
  Checkbox,
  CompanyNameInput,
  CountryCodeDropdown,
  Input,
  SearchableDropdown,
} from '@/components/forms/newRegistration';
import { useNewRegistration, useTranslations } from '@/hooks';
import {
  getBasePathname,
  getExternalLinks,
  sortCountriesAlphabetically,
} from '@/utils';

import styles from './index.css';
import { RegisterDesktopProps } from './types';

const RegisterDesktop: FC<RegisterDesktopProps> = ({ csrf, lang }) => {
  const {
    components: {
      forms: {
        emailAddress,
        emailAddressRegistration,
        confirmEmailAddressVerification,
        confirmEmailAddressVerificationRegistration,
        dataPrivacyCheckbox,
        termsAndConditionsCheckbox,
        country: countryTranslation,
        companyName,
        firstName: firstNameTranslation,
        lastName: lastNameTranslation,
        phoneNumber: phoneNumberTranslation,
        register,
        previous,
        next,
        cancel,
        sex: sexTranslation,
        language: languageTranslation,
      },
    },
  } = useTranslations(lang);

  const {
    inputRef,
    errors,
    isLoading,
    desktopSteps,
    desktopCurrentStep,
    company,
    countries,
    country,
    languagesOptions,
    language,
    sexOptions,
    sex,
    firstName,
    lastName,
    phoneCountryCode,
    phoneNumber,
    email,
    confirmEmail,
    dataPrivacyTreatment,
    termsAndConditions,
    toggleIsLoading,
    handleDesktopCompanyForm,
    getCompanyList,
    handleCompany,
    validateCompany,
    validateCountry,
    resetCompanyOnCountrySelection,
    handleCountry,
    handleLanguage,
    validateLanguage,
    validateSex,
    handleSexRadio,
    validateFirstName,
    validateLastName,
    handleFirstNameInput,
    handleDesktopPersonalForm,
    handlePreviousStep,
    handlePhoneCountryCodeInput,
    handlePhoneNumberInput,
    handleLastNameInput,
    validatePhoneNumber,
    validateEmail,
    handleEmailInput,
    validateConfirmEmail,
    handleConfirmEmaillInput,
    handleTermsAndConditionsCheckbox,
    handleDataPrivacyTreatmentCheckbox,
    handleDesktopEmailForm,
  } = useNewRegistration({ lang });

  return (
    <>
      <ProgressIndicator
        steps={desktopSteps}
        spaceEqually
        currentIndex={desktopCurrentStep}
      />
      {desktopCurrentStep === 0 && (
        <form onSubmit={handleDesktopCompanyForm} className={styles.form}>
          <input type="hidden" id="_token" name="_token" value={csrf} />
          <SearchableDropdown
            label={countryTranslation}
            options={sortCountriesAlphabetically(countries)}
            id="countries"
            handler={handleCountry}
            value={country}
            invalid={validateCountry()}
            invalidText={country?.invalidText}
            placeholder={countryTranslation}
            isDisabled={isLoading}
            lang={lang}
            resetFields={resetCompanyOnCountrySelection}
            tabOrder={2}
            tabIndex={2}
            margin={1.3}
            dataTestId={'registration-page-country-dropdown'}
            dropdownTestId={'country'}
          />
          <CompanyNameInput
            label={companyName}
            lang={lang}
            APICall={getCompanyList}
            value={company}
            id="companyName"
            dataTestId="register-form-company-name-input"
            sideButtonDataTestId="register-form-company-name-search"
            onSelection={handleCompany}
            placeholder={companyName}
            invalid={validateCompany()}
            invalidText={company?.invalidText}
            inputRef={inputRef}
            isDisabled={isLoading}
            toggleDisabledAllFields={toggleIsLoading}
            tabOrder={3}
            tabIndex={3}
          />
          <SearchableDropdown
            label={languageTranslation}
            options={languagesOptions}
            id="language"
            handler={handleLanguage}
            value={language}
            invalid={validateLanguage()}
            invalidText={language?.invalidText}
            margin={1.3}
            isDisabled={isLoading}
            tabOrder={4}
            lang={lang}
            placeholder={languageTranslation}
            dataTestId={'registration-page-language-dropdown'}
            dropdownTestId={'language'}
          />
          <BottomButtons
            submitLabel={next}
            rightDataTestId="registration-form-next-button"
            hasSubmitIcon
            leftButtonLabel={cancel}
            leftButtonIcon="back"
            leftButtonHref={getBasePathname(lang, 'login')}
            rightButtonTabOrder={5}
            width="percentage"
          />
          <NeedHelp lang={lang} isDisabled={isLoading} />
        </form>
      )}
      {desktopCurrentStep === 1 && (
        <form onSubmit={handleDesktopPersonalForm} className={styles.form}>
          <RadioButton
            groupLabel={sexTranslation}
            handler={handleSexRadio}
            name="sex"
            options={sexOptions}
            invalid={validateSex()}
            invalidText={sex?.invalidText}
            fieldKey="sex"
            hasSeparator={false}
            value={sex?.value}
            isDisabled={isLoading}
            orientation="horizontal"
            data-testid="register-form-radio"
          />
          <Input
            name="firstName"
            id="firstName"
            type="text"
            required
            invalid={validateFirstName()}
            invalidText={firstName?.invalidText}
            label={firstNameTranslation}
            data-testid="register-form-first-name-input"
            value={firstName?.value}
            placeholder={firstNameTranslation}
            onChange={(e: any) => handleFirstNameInput(e, 'firstName')}
            hasSeparator={false}
            isDisabled={isLoading}
            tabOrder={7}
            tabIndex={1}
          />
          <Input
            name="lastName"
            id="lastName"
            type="text"
            required
            invalid={validateLastName()}
            invalidText={lastName?.invalidText}
            label={lastNameTranslation}
            data-testid="register-form-last-name"
            value={lastName?.value}
            placeholder={lastNameTranslation}
            onChange={(e: any) => handleLastNameInput(e, 'lastName')}
            hasSeparator={false}
            isDisabled={isLoading}
            tabOrder={8}
            tabIndex={1}
          />
          <div className={styles.countryCodeContainer}>
            <CountryCodeDropdown
              customStyle={styles.countryCode}
              items={sortCountriesAlphabetically(countries)}
              onChange={handlePhoneCountryCodeInput}
              lang={lang}
              selected={{ ...phoneCountryCode }}
            />
            <Input
              name="phone"
              id="phone"
              type="text"
              required
              invalid={validatePhoneNumber()}
              invalidText={phoneNumber?.invalidText}
              label={phoneNumberTranslation}
              data-testid="register-form-phone-input"
              value={phoneNumber?.value}
              placeholder={phoneNumberTranslation}
              onChange={(e: any) => handlePhoneNumberInput(e, 'phoneNumber')}
              hasSeparator={false}
              margin={1.5}
              padding={3.7}
              isDisabled={isLoading}
              tabOrder={9}
            />
          </div>
          <BottomButtons
            submitLabel={next}
            hasSubmitIcon
            leftButtonLabel={previous}
            leftButtonOnClick={handlePreviousStep}
            leftButtonIcon="back"
            rightButtonTabOrder={10}
            width="percentage"
            rightDataTestId="registeration-form-step1-next-button"
          />
          <NeedHelp lang={lang} isDisabled={isLoading} />
        </form>
      )}
      {desktopCurrentStep === 2 && (
        <form onSubmit={handleDesktopEmailForm} className={styles.form}>
          <Input
            name="email"
            id="email"
            type="text"
            required
            invalid={validateEmail()}
            invalidText={email?.invalidText}
            label={emailAddress}
            data-testid="register-form-confirm-email-input_1"
            value={email?.value}
            placeholder={emailAddressRegistration}
            onChange={(e: any) => handleEmailInput(e, 'email')}
            hasSeparator={false}
            isDisabled={isLoading}
            tabOrder={6}
          />
          <Input
            name="confirmEmail"
            id="confirmEmail"
            type="text"
            required
            invalid={validateConfirmEmail()}
            invalidText={confirmEmail?.invalidText}
            label={confirmEmailAddressVerification}
            data-testid="register-form-confirm-email-input_2"
            value={confirmEmail?.value}
            placeholder={confirmEmailAddressVerificationRegistration}
            onChange={(e: any) => handleConfirmEmaillInput(e, 'confirmEmail')}
            hasSeparator={false}
            isDisabled={isLoading}
            tabOrder={6}
          />
          <Separator>
            <div className={styles.checkboxContainer}>
              <Checkbox
                handleCheckbox={() => handleTermsAndConditionsCheckbox()}
                isChecked={termsAndConditions?.value}
                id="termsAndConditions"
                label={termsAndConditionsCheckbox}
                invalid={
                  typeof termsAndConditions?.invalidText === 'undefined'
                    ? false
                    : true
                }
                invalidText={termsAndConditions?.invalidText}
                isDisabled={isLoading}
                tabOrder={12}
                url={getExternalLinks('/terms-and-conditions/', lang)}
                dataTestId="termsAndConditionEmailCheckbox"
              />
              <Checkbox
                handleCheckbox={() => handleDataPrivacyTreatmentCheckbox()}
                isChecked={dataPrivacyTreatment?.value}
                id="privacyTretment"
                label={dataPrivacyCheckbox}
                invalid={
                  typeof dataPrivacyTreatment?.invalidText === 'undefined'
                    ? false
                    : true
                }
                invalidText={dataPrivacyTreatment?.invalidText}
                margin={1}
                isDisabled={isLoading}
                tabOrder={13}
                url={getExternalLinks('/data-protection/', lang)}
                dataTestId="dataPrivacyCheckbox"
              />
            </div>
          </Separator>
          <BottomButtons
            submitLabel={register}
            hasSubmitIcon
            leftButtonLabel={previous}
            leftButtonOnClick={handlePreviousStep}
            isLoading={isLoading}
            isDisabled={isLoading}
            rightButtonTabOrder={14}
            width="percentage"
            rightDataTestId="registeration-form-signup-button"
          />
          <NeedHelp lang={lang} isDisabled={isLoading} />
          <ErrorMessages errorMessages={errors} />
        </form>
      )}
    </>
  );
};

export default RegisterDesktop;
